import { Documento } from './documento';
import { DetallePedido } from './detalle-pedido';
import { AsignacionEntradaPedido } from './asignacion-entrada-pedido';

export class Pedido extends Documento {
  estatusId: number; /// 1 = Abierto, 2 Cerrado
  detalles: DetallePedido[] = [];
  detalleCompleto: DetallePedido[] = [];
  asignacionPedidos: AsignacionEntradaPedido[];
  almacenReabastecimientoCodigo: string;
  almacenReabastecimientoId: number;
  almacenReabastecimientoDescripcion: string;
  linea: number;
  ruta: string;
}
