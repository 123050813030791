import { Almacen } from "./Almacen";
import { Pantallas } from "./pantallas";

export class Credencial {
  roles: [];
  token: string;
  expira: Date;
  tokenActualizable: string;
  tipoUsuarioDescripcion: string;
  nombreCompleto: string;
  pantallasOperacion: Pantallas[];
  pantallasConfiguracion: Pantallas[];
  pantallasSeguridad: Pantallas[];
  pantallasCatalogos: Pantallas[];
  pantallasReportes: Pantallas[];
  usuarioAlmacen: Almacen[];

  tipoUsuarioId: number;
  empresaId: number;
  usuarioId: number;
  almacenId: number;
  entradaId: number;
  lang: string;
  almacenDescripcion: string;
  app: string;
}
