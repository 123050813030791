export class DetalleDesktop {
    id: number;
    cantidad: number;
    productoId: number;
    productoDescripcion: string;
    productoCodigo: string;
    unidadMedidaCodigo: string;
    abreviaturaMedida: string;
    sku: string;
    material: string;
    otro: string;
    orden: string;
    fechaSolicitud: Date | string;
    codigoProductoReemplazo: string;
    maquinariaId: number;
    maquinariaDescripcion: string;
    comentarios: string;
  }
  