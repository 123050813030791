import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Router } from '@angular/router';
import { Salida } from 'src/app/shared/models/Salida';
//import { ReporteInventarioVexan } from 'src/app/services/db/base/db.service';
//import { ComprobanteSalidaComponent } from '../../comprobante-salida/catalogo.component';
import { Subscription } from 'rxjs';
//import { SesionService } from 'src/app/services/db/base/sesion.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { ComprobanteSalidaComponent } from '../comprobante-salida/catalogo.component';
import { ImprimirComprobanteSalidaComponent } from '../imprimir-comprobante-salida/catalogo.component';
import { ComprobanteEntradaComponent } from '../../control-entradas/comprobante-entrada/catalogo.component';
import { FormaDocumentoExcelEntradasComponent } from '../../control-entradas/documentos-excel/forma.component';
import { ComprobanteSalidaCatalogoComponent } from './comprobante-salida-catalogo/catalogo.component';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-control-inventario-salidas',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlSalidasComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  subSesion: Subscription;

  fuenteDatos: MatTableDataSource<Salida> = new MatTableDataSource([]);
  columnasMostradas = [
    'almacenDescripcion',
    'folio',
    'referencia',
    'fechaDocumento',
    'nombreUsuario',
    //'empleado',
    'esBorrador',
    'opciones',
  ];

  constructor(
    public ventana: MatDialog,
    private db: Contexto,
    public autenticacion: AutenticacionService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    private alerta: ServicioAlerta,
    private router: Router,
    private dialog: MatDialog,
    //public servicioSesion: SesionService

  ) { }

  ngOnInit() {
    this.subSesion = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );
  }

  cargarTabla() {
    this.db.controlSalidas.ObtenerListaSalidas(this.autenticacion.credencial.almacenId).toPromise().then((salidas) => {
      this.fuenteDatos = new MatTableDataSource(salidas);
      this.fuenteDatos.sort = this.ordenador;
      this.fuenteDatos.paginator = this.paginador;
    });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  abrirForma(id?: string): void {
    const dato = id ? id : 0;
    this.router.navigate(['/RegistroSalidas/', dato]);
  }

  verComprobante(salida: Salida): void {
    this.db.controlSalidas.ObtenerSalidaId(salida.id).toPromise().then((s) => {
      this.dialog.open(ComprobanteSalidaCatalogoComponent, {
        data: s,
        panelClass: 'comprobante',
      });
    });
  }

  imprimirComprobanteDetalle(salida: Salida): void {
    this.db.controlSalidas.ObtenerSalidaId(salida.id).toPromise().then((e) => {
      this.dialog.open(ImprimirComprobanteSalidaComponent, {
        data: e,
        panelClass: 'comprobante',
      });
    });
  }

  descargarGuiaLayout() {
    let archivo = window.open("assets/guias-excel/Layout_Entradas_Desktop.xlsx", '_blank');
    setTimeout(function () {
      archivo.close();
    }, 100);
    return false;
  }

  async cargarExcel() {
    let forma = this.ventana.open(FormaDocumentoExcelEntradasComponent, { data: {}, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => {
      this.cargarTabla();
    });

  }

  ngOnDestroy(): void {
    if (this.subSesion != undefined) {
      this.subSesion.unsubscribe();
    }
  }
}