import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Conteos } from '../models/conteos';
import { Periodos } from '../models/periodos';
import { AsignacionProductoCritico } from '../models/asignacion-producto-critico';
import { ConteoProducto } from '../models/conteoProducto';
import { RangoFechaSincronizacion } from '../models/rango-fecha-sincro';
import { RestriccionFecha } from '../models/restriccionFecha';
import { Respuesta } from '../models/respuesta';

export class RepositorioControlCalcularConteos extends Repositorio<
  Conteos
> {
  constructor(http: HttpClient) {
    super(http, 'ControlCalcularConteo');
  }
  
  ObtenerCatalogoConteos(almacenId: number): Observable<Conteos[]> {
    const ruta = `${this.ruta}/ObtenerCatalogoConteos/${almacenId}`;
    return this.http.get<Conteos[]>(ruta);
  }

  calcularConteo(almacenId: number): Observable<void>{
    const ruta = `${this.ruta}/CalcularConteo/${almacenId}`;
    return this.http.post<void>(ruta,null);
  }

  PeriodoActivo(almacenId: number): Observable<Periodos[]>{
    const ruta = `${this.ruta}/PeriodoActivo/${almacenId}`;
    return this.http.get<Periodos[]>(ruta);
  }

  EstaCalculado(almacenId: number): Observable<Periodos[]>{
    const ruta = `${this.ruta}/EstaCalculado/${almacenId}`;
    return this.http.get<Periodos[]>(ruta);
  }

  ExisteListaCritica(almacenId: number): Observable<AsignacionProductoCritico[]>{
    const ruta = `${this.ruta}/ExisteListaCritica/${almacenId}`;
    return this.http.get<AsignacionProductoCritico[]>(ruta);
  }

  ExisteRestriccionFechaMensual(almacenId: number): Observable<RestriccionFecha[]>{
    const ruta = `${this.ruta}/ExisteRestriccionFechaMensual/${almacenId}`;
    return this.http.get<RestriccionFecha[]>(ruta);
  }

  ObtenerComprobanteConteo(id: number, almacenId: number): Observable<ConteoProducto[]>{
    const ruta = `${this.ruta}/ObtenerComprobanteConteo/${id}/${almacenId}`;
    return this.http.get<ConteoProducto[]>(ruta);
  }

  ObtenerComprobanteCalculoConteo(almacenId: number): Observable<ConteoProducto[]>{
    const ruta = `${this.ruta}/ObtenerComprobanteCalculoConteo/${almacenId}`;
    return this.http.get<ConteoProducto[]>(ruta);
  }
} 
