import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, Sort, MatDialog } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { Productos } from 'src/app/shared/models/Productos';
import { Entrada } from 'src/app/shared/models/entrada';
import { DetalleEntrada } from 'src/app/shared/models/detalle-entrada';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Pedido } from 'src/app/shared/models/pedido';
import { AsignacionEntradaPedido } from 'src/app/shared/models/asignacion-entrada-pedido';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { FormaMensajeComponent } from 'src/app/utilerias/mensaje-en-modal/forma/forma.component';
import { LoadingService } from 'src/app/loading/loading.service';
import { ValidadorFormas } from 'src/app/utilerias/validadorFormas';
import { Contexto } from 'src/app/shared/api/contexto.service';

@AutoUnsubscribe()
@Component({
  templateUrl: './doc-entrada.component.html',
  styleUrls: ['./doc-entrada.component.scss'],
})

export class FormaDocEntradaComponent implements OnInit {
  forma: FormGroup;
  entradaId: number = 0;
  entrada: Entrada = new Entrada();
  entradaTemp: Entrada[] = [];
  detalleEntradas: DetalleEntrada[] = [];
  pedidos: Pedido[] = [];
  asignaciones: AsignacionEntradaPedido[] = [];
  contadorPedidoSelecionado: number = 1;
  detalle: DetalleEntrada;
  detallePedidos: DetallePedido[] = [];
  folio: string;
  get f() {
    return this.forma.controls;
  }
  almacenId: number = 0;
  productos: Productos[] = [];
  motivos: number[] = [];
  descripcionMotivoLabel: string = '';

  textoBuscar: string = '';

  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<DetalleEntrada> = new MatTableDataSource([]);
  fuenteDatosEntrada: MatTableDataSource<Entrada> = new MatTableDataSource([]);

  columnasMostradas = [
    'linea',
    'cantidad',
    'abreviaturaMedida',
    'producto',
    'codigoProductoReemplazo',
    'fecha',
    'comentarios',
    'opciones',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta,
    private activateRouter: ActivatedRoute,
    private db: Contexto,
    private autenticacion: AutenticacionService,
    private dialog: MatDialog,
    public ventana: MatDialog,
    private router: Router,
    private cargando: LoadingService,
    private validadorForma: ValidadorFormas,
  ) {
    this.activateRouter.params.subscribe((params) => {
      this.entradaId = +params['id'];
    });

  }

  async ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [undefined, Validators.nullValidator],
      folio: ['', Validators.required],
      fechaDocumento: [new Date()],
      fechaRecepcion: [new Date(), Validators.required],
      embarque: ['', Validators.nullValidator],
      referencia: ['', Validators.nullValidator],
      motivo: [null, Validators.required],
      descripcionMotivo: ['', Validators.nullValidator],
      comentarios: ['', Validators.nullValidator],
      esBorrador: [false, Validators.required],
      cantidadTotalEntrada: [0, Validators.nullValidator],
      totalLineas: [0, Validators.nullValidator],
      almacenId: [0, Validators.nullValidator],
      almacenCodigo: ['', Validators.nullValidator],
      almacenDescripcion: ['', Validators.nullValidator],
      ubicacionDescripcion: ['', Validators.nullValidator],
      usuarioId: [0, Validators.nullValidator],
      usuarioNombreCompleto: ['', Validators.nullValidator],
      computadorasId: [0, Validators.nullValidator],
    });

    //VALIDO QUE LA FECHA DE DOCUMENTO NO SEA MENOR A LA ULTIMA CREADA
    this.forma.get('fechaDocumento').valueChanges.subscribe(async () => {
      await this.asignarFechaValidacion();
    });
    this.forma.get('motivo').valueChanges.subscribe(value => {
      this.updateDescripcionMotivoLabel(value);
    });


    await this.obtenerMotivos();
    this.almacenId = this.autenticacion.credencial.almacenId;
    await this.cargarTabla();
    await this.cargarProductos();

    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
  }

  //Carga la tabla para traer la Ubicación física, Almacén, y la información para el folio.
  async cargarTabla() {
    this.db.controlEntradas.ObtenerListaEntradas(this.autenticacion.credencial.almacenId).toPromise().then(resultado => {
      this.fuenteDatosEntrada = new MatTableDataSource(resultado);
      this.fuenteDatosEntrada.paginator = this.paginador;
      this.fuenteDatosEntrada.sort = this.ordenador;

      //Setea el valor de la Ubicación física y de Almacen dependiendo de cual Almacen se esté trabajando
      this.forma.controls['ubicacionDescripcion'].setValue(this.fuenteDatosEntrada.filteredData[0].ubicacionDescripcion);
      this.forma.controls['almacenDescripcion'].setValue(this.fuenteDatosEntrada.filteredData[0].almacenDescripcion);

      //Crea el Folio basandose en: IN - Codigo del Almacen - Id de Usuario - Sumatoria de entradas + 1
      this.folio = `IN-${this.fuenteDatosEntrada.filteredData[0].almacenCodigo}-${this.autenticacion.credencial.usuarioId}-${this.fuenteDatosEntrada.filteredData.length + 1}`;
      this.forma.controls['folio'].setValue(this.folio);
      this.recargarFormulario();
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar los detalles de la entrada');
      })
  }
  async obtenerMotivos() {
    this.db.controlEntradas.ObtenerTipoMotivos().toPromise().then(resultado => {
      this.motivos = resultado;
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar los motivos');
      })
  }
  updateDescripcionMotivoLabel(motivoId: number) {
    if (motivoId == 1) {
      this.descripcionMotivoLabel = "Orden de Compra";
    } else if (motivoId == 2) {
      this.descripcionMotivoLabel = "Folio de Salida";
    } else if (motivoId == 3) {
      this.descripcionMotivoLabel = "Motivo de Ajuste";
    } else if (motivoId == 4) {
      this.descripcionMotivoLabel = "Almacen de Origen";
    } else {
      this.descripcionMotivoLabel = 'Descripción del Motivo';
    }
  }

  //#region VALIDAR FECHA DE DOCUMENTO
  async asignarFechaValidacion() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const ultimaFecha = await this.db.controlEntradas.ObtenerFechaUltimaEntrada(this.almacenId).toPromise();
    const fecha = new Date(ultimaFecha['fechaDocumento']);
    const fechaControl = this.forma.get('fechaDocumento');
    fechaControl.setValidators([Validators.required, this.validarFecha(fecha), this.validarFechaFutura(hoy)]);

  }

  validarFecha(ultimaFecha: Date) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let resultado = {
        fechaInvalida: `La fecha debe ser mayor ${ultimaFecha.toLocaleString()}`,
      };
      const valor = control.value;
      let fecha: Date;
      if (typeof valor === 'string') {
        fecha = new Date(valor);
      } else {
        fecha = valor;
      }
      if (!ultimaFecha) {
        resultado = null;
      } else if (fecha) {
        resultado = ultimaFecha < fecha ? null : resultado;
      }
      return resultado;
    };
  }

  validarFechaFutura(fechaActual: Date) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let resultado = { fechaInvalida: `La fecha seleccionada no debe ser mayor la fecha actual ${fechaActual.toLocaleString()}`, };
      const valor = control.value;
      let fecha: Date;
      if (typeof valor === 'string') {
        fecha = new Date(valor);
      } else {
        fecha = valor;
      }
      if (!fechaActual) {
        resultado = null;
      } else if (fecha) {
        resultado = fechaActual > fecha ? null : resultado;
      }
      return resultado;
    };

  }
  //#endregion

  async recargarFormulario() {
    if (this.entradaId == 0) {
      this.entrada = await this.db.controlEntradas.CrearEntrada(this.almacenId, this.autenticacion.credencial.usuarioId, this.folio, this.autenticacion.credencial.nombreCompleto).toPromise();
      Object.assign(this.forma.value, this.entrada);
      this.forma.reset(this.forma.value);
      this.cargarDetalleEntrada();
    } else { //TODO verificarlo
      this.entradaTemp = await this.db.controlEntradas.ObtenerEntradaId(this.entradaId).toPromise();
      this.entrada = this.entradaTemp[0];
      this.asignaciones = this.entrada.asignacionPedidos && this.entrada.asignacionPedidos.length > 0 ? this.entrada.asignacionPedidos : null;
      Object.assign(this.forma.value, this.entrada);
      this.forma.reset(this.forma.value);
      this.cargarDetalleEntrada();
    }
  }

  //#region CARGAR LOS DETALLES DE LA ENTRADA
  cargarDetalleEntrada() {
    this.detalleEntradas = this.entrada.detalles;
    if (this.detalleEntradas.length == 0) {
      this.detalle = new DetalleEntrada();
      this.detalle.id = undefined;
      this.detalle.linea = 1;
      this.detalle.cantidad = 0;
      this.detalle.cantidadRequisicion = 0;
      this.detalle.folioRequi = '',
        this.detalle.fechaSolicitud = new Date();
      this.detalle.orden = '';
      this.detalle.entradaId = undefined;
      this.detalle.productoCodigo;
      this.detalle.productoCodigoDescripcion = '';
      this.detalle.productoDescripcion = '';
      this.detalle.productoId = 0;
      this.detalle.comentario = '';
      this.detalle.unidadMedidaCodigo = '';
      this.detalle.codigoProductoReemplazo = '';
      this.detalle.abreviaturaMedida = '';
      // this.detalle.existencia = 0;
      this.detalle.bloqueado = false;
      this.detalle.remarcar = false;

      this.detalleEntradas.push(this.detalle);

      this.fuenteDatos = new MatTableDataSource<DetalleEntrada>(this.detalleEntradas);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.conteoLineasCantidad();
    } else {
      this.fuenteDatos = new MatTableDataSource<DetalleEntrada>(this.detalleEntradas);
      this.fuenteDatos.paginator = this.paginador;
      this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    }
  }

  //#endregion

  //#region  LIMPIAR TODO EL FORMULARIO

  limpiar(): void { }
  //#endregion

  //#region GUARDAR EL DOCUMENTO DE LA ENTRADA
  async guardar(tipo: boolean) {
    //GUARDO EL PEDIDO COMO DOCUMENTO BORRADOR

    //let accion: any;
    let estado = 1;
    const entrada = this.forma.value as Entrada;
    entrada.fechaRegistro = new Date();
    entrada.esBorrador = tipo;
    entrada.detalles = this.detalleEntradas;
    entrada.asignacionPedidos = this.asignaciones;

    //VALIDO QUE NINGUNA LINEA SE VAYA EN PRODUCTO 0 ---> NULL
    var productoNull = this.detalleEntradas
      .filter((e) => e.productoId == 0 || e.productoCodigo == "")
      .map((e) => (e.remarcar = true));

    if (productoNull.length > 0) {
      this.alerta.mostrarAdvertencia(
        '¡Aún hay productos por capturar!'
      );
      return;
    } else if (entrada.motivo == 0) {
      this.alerta.mostrarAdvertencia(
        '¡Selecciona un motivo de entrada!'
      );
      return;
    }

    //SI TIENE REQUISICIONES EL PEDIDO, NO PERMITE GUARDAR COMO BORRADOR
    if (tipo && entrada.asignacionPedidos && entrada.asignacionPedidos.length > 0) {
      this.alerta.mostrarError('Entrada por requisicion NO se permite guardar como borrador');
      return;
    }


    if (entrada.asignacionPedidos && entrada.asignacionPedidos.length > 0) {
      const faltante = entrada.detalles
        .map((e) => {
          let falta = e.cantidadRequisicion - e.cantidad;
          falta = falta < 0 ? 0 : falta;
          return falta;
        })
        .reduce((a, b) => a + b);

      //PREGUNTO SI QUIERE QUE SE CIERREN LAS REQUISICIONES O SE QUEDEN ABIERTAS
      if (faltante > 0) {

        estado = await this.mensaje(
          'Aún cuenta con detalle faltante. ' +
          '¿Comó desea guardar las requisiciones incompletas?',
          false,
          false,
          false,
          true,
          true
        );
      } else {
        estado = 2
      }
      if (estado === 4) {
        return;
      }
    }
    this.cargando.show("Espera un momento... Generando una nueva entrada");
    const accion =
      this.entrada.id > 0
        ? this.db.controlEntradas.ModificarEntradaBD(entrada)
        : this.db.controlEntradas.EnviarEntradaBD(entrada);

    accion.toPromise()
      .then(() => {
        this.cargando.hide();
        this.alerta.mostrarExito('Documento guardado');
        this.router.navigate(['ControlEntradas']);
        if (entrada.esBorrador == false) {
          this.db.controlEntradas.AgregarAlInventario(entrada).toPromise();
          this.db.controlEntradas.AgregarAlPedido(entrada, estado).toPromise();
        }
      })
      .catch((e) => {
        this.cargando.hide();
        this.alerta.mostrarError('Error al guardar');
      });
  }
  //#endregion

  //#region BUSCAR EL PRODUCTO DENTRO DEL DETALLE
  buscar(event) {
    this.textoBuscar = event.target.value;
  }
  //#endregion

  //#region ACTUALIZAR LA LINEA O EL ROW DEL DETALLE
  async actualizaLinea(
    linea: number,
    cantidad?: number,
    abreviaturaMedida?: string,
    fecha?: Date,
    producto?: Productos,
    comentario?: string
  ) {
    const detalle = this.fuenteDatos.data.find((e) => e.linea == linea);
    detalle.id = undefined;
    detalle.linea = linea;
    detalle.cantidad = +cantidad;
    detalle.entradaId = undefined;
    if (producto.id > 0) {
      detalle.productoCodigo = producto.codigo;
      detalle.productoDescripcion = producto.productoDescripcion;
      detalle.productoCodigoDescripcion = producto.productoCodigoDescripcion;
      detalle.productoId = producto.id;
      detalle.unidadMedidaCodigo = producto.unidadMedidaCodigoPiezaDesc;
      detalle.abreviaturaMedida = producto.unidadMedidaDescripcion;
      detalle.sku = producto.sku;
      detalle.otro = producto.otro;
      detalle.material = producto.material;
      detalle.codigoProductoReemplazo = producto.codigoProductoReemplazo;

    }
    detalle.comentario = comentario;
    if (detalle.esRequisicion) {
      detalle.fechaSolicitud = detalle.fechaSolicitud;
      detalle.cantidadRequisicion = detalle.cantidadRequisicion;
      detalle.folioRequi = detalle.folioRequi;
      detalle.abreviaturaMedida = detalle.abreviaturaMedida;
      detalle.bloqueado = true;
    } else {
      detalle.fechaSolicitud = fecha;
      detalle.cantidadRequisicion = 0;
      detalle.folioRequi = '';
      detalle.bloqueado = false;
    }
    detalle.remarcar = false;
    this.conteoLineasCantidad();
  }
  //#endregion

  //#region AGREGA NUEVA LINEA AL DETALLE Y A LA TABLA
  agregarLinea() {
    const detalle = new DetalleEntrada();
    detalle.id = undefined;
    detalle.linea = this.detalleEntradas.length + 1;
    detalle.cantidad = 0;
    detalle.fechaSolicitud = new Date();
    detalle.abreviaturaMedida = '';
    detalle.productoCodigo = '';
    detalle.productoDescripcion = '';
    detalle.productoCodigoDescripcion = '';
    detalle.productoId = 0;
    detalle.comentario = '';
    detalle.bloqueado = false;
    detalle.cantidadRequisicion = 0;
    detalle.folioRequi = '';
    detalle.entradaId = undefined;
    detalle.remarcar = false;
    detalle.unidadMedidaCodigo = '';
    detalle.codigoProductoReemplazo = '';
    this.detalleEntradas.push(detalle);
    this.fuenteDatos = new MatTableDataSource(this.detalleEntradas);
    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
    this.ordenadoPorLineaDesc();
    this.conteoLineasCantidad();
    this.textoBuscar = '';
  }
  //#endregion

  //#region REORDENA LA LISTA POR NUMERO DE LINEA DE MENOR A MAYOR
  ordenadoPorLineaDesc() {
    const sortState: Sort = { active: 'linea', direction: 'asc' };
    this.ordenador.active = sortState.active;
    this.ordenador.direction = sortState.direction;
    this.ordenador.sortChange.emit(sortState);
  }
  //#endregion

  //#region IDENTIFICA LA CANTIDAD DE LINEAS Y PRODUCTOS RECIBIDOS
  conteoLineasCantidad() {
    this.f['cantidadTotalEntrada'].setValue(0);
    this.f['totalLineas'].setValue(0);



    let totalEntrada = this.fuenteDatos.data.reduce((suma, detalle) => suma + +detalle.cantidad, 0);
    let totalRequisicion = this.fuenteDatos.data.reduce((suma, detalle) => suma + +detalle.cantidadRequisicion, 0);

    this.f['cantidadTotalEntrada'].setValue(totalEntrada.toFixed(2));
    this.f['totalLineas'].setValue(this.fuenteDatos.data.length);

  }
  //#endregion

  //#region ELIMINA LA LINEA DEL DETALLE Y DE LA TABLA, REORDENA Y REALIZA EL CONTEO DE LAS CANTIDADES DE LA LINEA
  removerLinea(linea: number) {
    if (this.detalleEntradas.length > 1) {
      this.detalleEntradas.splice(linea - 1, 1);
      let contador: number = 1;
      for (let index = 0; index < this.detalleEntradas.length; index++) {
        const element = this.detalleEntradas[index];
        element.linea = contador;
        contador++;
      }
      this.fuenteDatos = new MatTableDataSource(this.detalleEntradas);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    } else {
      this.limpiarProductos(linea);
    }
  }
  //#endregion

  //#region  PRODUCTOS EN DETALLE
  async cargarProductos() {
    this.productos = await this.db.controlEntradas.ObtenerListaProductosPorAlmacen(this.almacenId).toPromise();
  }
  //LIMPIA LA LINEA DEL DETALLE Y LA TABLA
  async limpiarProductos(linea: number) {
    const detalle = this.fuenteDatos.data.find((e) => e.linea == linea);
    if (!detalle.bloqueado) {
      detalle.id = undefined;
      detalle.cantidad = 0;
      detalle.fechaSolicitud = new Date();
      detalle.abreviaturaMedida = '';
      detalle.productoCodigo = '';
      detalle.productoDescripcion = '';
      detalle.productoCodigoDescripcion = '';
      detalle.productoId = 0;
      detalle.comentario = '';
      // detalle.existencia = 0;
      detalle.bloqueado = false;
      detalle.cantidadRequisicion = 0;
      detalle.folioRequi = '';
      detalle.entradaId = undefined;
      detalle.remarcar = false;
      detalle.unidadMedidaCodigo = '';
      detalle.codigoProductoReemplazo = '';
      this.textoBuscar = '';
    }
  }

  //#endregion

  //#region METODOS PARA LIMPIAR

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  filtrar(filtro: string) {
    this.fuenteDatos.filter = filtro;
  }
  //#endregion

  //#region LISTA DE REQUISCIONES Y REQUISICIONES SELECCIONADAS PARA CARGAR EL DETALLE
  /*
  seleccionarPedidos() {
    let contador: number = 1;

    this.dialog
      .open(ListaPedidosComponent, {
        panelClass: 'comprobante',
        data: this.pedidos,
      })
      .afterClosed()
      .toPromise()
      .then((pedidos: Pedido[]) => {
        if (pedidos) {
          this.eliminarTodos(); //LIMPIO LAS LISTAS PARA RECARGAR CON LOS NUEVOS PEDIDOS
          for (const p of pedidos) {
            this.asignaciones.push({
              pedidoId: p.id,
              pedidoFolio: p.folio,
              linea: contador,
              ruta: p.ruta
            } as AsignacionEntradaPedido);

            p.linea = contador;
            contador++
          }
          this.pedidos = pedidos;
          this.pedidosSeleccionados(this.pedidos);
        }
      });
  }

  //PERMITE VER EL DETALLE DEL PEDIDO SELECCIONADO EN EL CHIP
  /*verComprobante(pedidoId: number): void {
    this.db.pedidos.PedidoId(pedidoId).then((p) => {
      this.dialog.open(ComprobantePedidoComponent, {
        data: p,
        panelClass: 'comprobante',
      });
    });
  }

  //ELIMINAMOS EL PEDIDO TANTO DE LA LISTA COMO DEL CHIP
  eliminarPedido(linea: number, pedidoId: number) {
    //ELIMINO LAS ASIGNACIONES DE PEDIDOS
    if (this.asignaciones.length > 0) {
      this.asignaciones.splice(linea - 1, 1);
      let contadorAsigs: number = 1;
      for (
        let asignacion = 0;
        asignacion < this.asignaciones.length;
        asignacion++
      ) {
        const a = this.asignaciones[asignacion];
        a.linea = contadorAsigs;
        this.pedidos.find(e => e.id == a.pedidoId).linea = contadorAsigs;
        contadorAsigs++;
      }
    }
    
    //ELIMINO EL PEDIDO SELECCIONADO
    let l =  this.pedidos.find(e => e.id == pedidoId).linea
    this.pedidos.splice(l - 1, 1);

    //VERIFICO SI HAY PEDIDOS SELECCIONADOS Y LOS ASIGNO
    if (this.pedidos.length > 0) {
      this.pedidosSeleccionados(this.pedidos);
    } else {
      this.eliminarTodos();
      this.cargarDetalleEntrada();
      //SI NO HAY PEDIDOS SELECCIONADOS LIMPIO LAS LISTAS Y CARGO DE NUEVO EL DETALLE LIMPIO DE LA LISTA
    }
  }

  //LIMPIO LAS LISTAS
  eliminarTodos() {
    this.detallePedidos = [];
    this.detalleEntradas = [];
    this.pedidos = [];
    this.asignaciones = [];
  }

  //LE ASIGNOS LOS PEDIDOS SELECCIONADOS
  async pedidosSeleccionados(pedidos: Pedido[]) {
    let contador: number = 1;

    if (!pedidos) {
      return;
    }

    const detallePedidosArr = await Promise.all(
      pedidos.map(pedido => this.db.controlEntradas.ObtenerDetallePedidos(pedido.id).toPromise())
    );
    this.detallePedidos = detallePedidosArr.reduce((acc, curr) => acc.concat(curr), []);
    //AGRUPO POR PRODUCTO Y ORDEN DE COMPRA
    let d = this.detallePedidos.filter((e) => e.cantidadFaltante != 0);
    var helper = {};
    var detallePedidoAgrupado = d.reduce(function (r, o) {
      var key = o.productoId + '-' + o.orden;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o);
        r.push(helper[key]);
      } else {
        helper[key].cantidadFaltante += o.cantidadFaltante;
        helper[key].cantidad += o.cantidadFaltante;
      }
      return r;
    }, []);

    let detPedido = detallePedidoAgrupado as DetallePedido[];

    //REALIZO EL MAPEO DEL DETALLE PEDIDO AL DETALLE DE ENTRADA
    for (const detalle of detPedido) {

      const inventario = await this.db.controlEntradas.ObtenerInventario(
        this.almacenId,
        detalle.productoId
      ).toPromise();

      const pedidoFolioG = await this.db.controlEntradas.PedidoFolio(
        detalle.pedidoId
      ).toPromise();
      
      const entrada = {
        id: undefined,
        cantidad: detalle.cantidadFaltante,
        entradaId: undefined,
        cantidadRequisicion: detalle.cantidadFaltante,
        folioRequi: pedidoFolioG.folio,
        productoCodigo: detalle.productoCodigo,
        productoDescripcion: detalle.productoDescripcion,
        productoCodigoDescripcion: detalle.productoCodigo + ' ' + detalle.productoDescripcion,
        productoId: detalle.productoId,
        unidadMedidaCodigo: detalle.unidadMedidaCodigo,
        existencia: inventario.length > 0 ? inventario[0].cantidad : 0,
        orden: detalle.orden,
        fechaSolicitud: detalle.fechaSolicitud,
        material: detalle.material,
        otro: detalle.otro,
        linea: contador++,
        comentario: '',
        bloqueado: true,
        esRequisicion: true,
        codigoProductoReemplazo: detalle.codigoProductoReemplazo,
        abreviaturaMedida: detalle.abreviaturaMedida,
      } as DetalleEntrada;
      this.detalleEntradas.push(entrada);
    }
    this.fuenteDatos = new MatTableDataSource(this.detalleEntradas);
    this.fuenteDatos.paginator = this.paginador;
    this.conteoLineasCantidad();
  }
  //#endregion
  */
  //MENSAJE AYUDA A IDENTIFICAR LAS RESPUESTAS Y ASIGNA LA ACCION CORRESPONDIENTE
  async mensaje(
    mensaje: string,
    mostrarCargando?: boolean,
    botonOk?: boolean,
    botonCancelar?: boolean,
    botonAbierto?: boolean,
    botonCerrado?: boolean
  ): Promise<number> {
    let respuesta = 0;
    const dato = {
      mensaje,
      mostrarCargando,
      botonOk,
      botonCancelar,
      botonAbierto,
      botonCerrado,
    };
    const forma = this.ventana.open(FormaMensajeComponent, {
      data: dato,
      panelClass: 'form-container',
    });
    respuesta = await forma.afterClosed().toPromise();
    return respuesta;
  }
}
