import { DetalleDesktop } from "./detalle-desktop";

export class DetalleSalida extends DetalleDesktop{
  id: number;
  registro: Date;
  cantidad: number;
  productoPrincipalId:number;
  descipcionProductoPrincipal:string;
  productoDescripcion: string;
  productoId:number;
  referencia: string;
  folio:string;
  precio: number;
  precioUSD: number;
  totalMxn: number;
  totalUSD: number;
  solicitoEmpleado: string;
  cantidadFaltante: string;
  salidaId: number;
  inventarioCantidad: number;
  existencia: number;
  productoPorPieza: string;
  linea: number;
  bloqueado: boolean;
  remarcar:boolean;
  productoCodigoDescripcion: string;
  cantidadRequisicion: number;
  esRequisicion: boolean;
  piezaPorEquipo: string;
  comentario: string;
  taller: string;
  tecnico: string;
  maquinariaId: number;
  
}
