import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { PedidoSalida } from 'src/app/shared/models/pedido-salida';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Subscription } from 'rxjs';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';

@Component({
  selector: 'app-lista-pedidos-salidas',
  templateUrl: './lista-pedidos.component.html',
  styleUrls: ['./lista-pedidos.component.scss'],
})
export class ListaPedidosSalidasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  fuenteDatos = new MatTableDataSource<PedidoSalida>([]);
  seleccion = new SelectionModel<PedidoSalida>(true, []);
  seleccionados = new SelectionModel<PedidoSalida>(true, []);
  sub: Subscription;
  columnas = ['select', 'folio', 'referencia', 'fechaDocumento'];

  constructor(private db: Contexto,
    private ventana: MatDialogRef<ListaPedidosSalidasComponent>,
    @Inject(MAT_DIALOG_DATA) public dato: PedidoSalida[],
    private autenticacion: AutenticacionService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,) { }

  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.fuenteDatos.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.seleccion.clear()
      : this.fuenteDatos.data.forEach((row) => this.seleccion.select(row));
  }


  cargarPedidos() {
    this.db.controlSalidas
      .CargarPedidos(this.autenticacion.credencial.almacenId).toPromise()
      .then((pedidos) => {
        this.fuenteDatos = new MatTableDataSource(pedidos);

        if (this.dato && this.dato.length > 0) {
          this.fuenteDatos.data.forEach(pedido1 => {
            this.dato.forEach(pedido2 => {
              if (pedido1.id == pedido2.id) {
                this.seleccion.toggle(pedido1);
                this.seleccion.select(pedido1);
                this.seleccion.isSelected(pedido1);
              }
            });
          });
        }
      });
  }
  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarPedidos();
      }
    );
  }
}
