import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { NavComponent } from './core/nav/nav.component';
import { PaginaLoginComponent } from './core/login/components/pagina/pagina.component';
import { GuardiaAcceso } from './core/guards/guardias.service';
import { CatalogoUsuarioComponent } from './shared/components/usuarios/catalogo/catalogo.component';
import { CatalogoTipoUsuarioComponent } from './shared/components/tipo-usuarios/catalogo/catalogo.component';
import { CatalogoRolComponent } from './shared/components/rol/catalogo/catalogo.component';
import { CatalogoDashBoardComponent } from './shared/components/dashboard/catalogo/catalogo.component';
import { CatalogoTipoMenuComponent } from './shared/components/tipo-menus/catalogo/catalogo.component';
import { CatalogoPantallaComponent } from './shared/components/pantallas/catalogo/catalogo.component';
import { FormaAsignacionRolPantallaComponent } from './shared/components/asignacion-rol-pantalla/forma/forma.component';
import { PaginaRegistroClienteComponent } from './core/login/components/registro-cliente/pagina.component';
import { FormContactoSoporteComponent } from './shared/components/contacto-soporte/forma/forma.component';
import { CatalogoContactoSoporteComponent } from './shared/components/contacto-soporte/catalogo/catalogo.component';
import { GuardiaMenuSeguridad } from './core/guards/guardias-seguridad.service';
import { CatalogoNoAccesoComponent } from './shared/components/no-acceso/catalogo/catalogo.component';
import { GuardiaMenuCatalogos } from './core/guards/guardias-catalogos.service';
import { CatalogoGrupoProductosComponent } from './shared/components/grupo-productos/catalogo/catalogo.component';
import { FormaGrupoProductoComponent } from './shared/components/grupo-productos/forma/forma.component';
import { CatalogoUnidadesMedida } from './shared/components/UnidadesMedida/catalogo/catalogo.component';
import { CatalogoEmpresaMinera } from './shared/components/empresaMinera/catalogo/catalogo.component';
import { CatalogoMinas } from './shared/components/Minas/catalogo/catalogo.component';
import { CatalogoAlmacen } from './shared/components/Almacen/catalogo/catalogo.component';
import { CatalogoProductos } from './shared/components/Productos/catalogo/catalogo.component';
import { CatalogoEmpleados } from './shared/components/Empleados/catalogo/catalogo.component';
import { CatalogoMinaUbicaciones } from './shared/components/mina-ubicaciones/catalogo/catalogo.component';
import { CatalogoAsignacionAlmacenProducto } from './shared/components/asignacion-usuario-producto/catalogo/catalogo.component';
import { CatalogoAsignacionMinaEmpleado } from './shared/components/asignacion-mina-empleado/catalogo/catalogo.component';
import { CatalogoArticulosAlternativos } from './shared/components/articulos-alternativos/catalogo/catalogo.component';
import { CatalogoReporteador } from './shared/components/reporteador/catalogo/catalogo.component';
import { CatalogoIndicadores } from './shared/components/Inidicadores/catalogo.component';
import { FormaListaComponent } from './shared/components/articulos-alternativos/formaLista/formaLista.component';
import { CanDeactiveGuard } from './core/guards/guardina-deactivate';
import { CatalogoProcesoEstatus } from './shared/components/ProcesoEstatus/catalogo/catalogo.component';
import { CatalogoControlAjustes } from './shared/components/control-ajustes/catalogo/catalogo.component';
import { FormaAutorizarAjuste } from './shared/components/control-ajustes/forma/forma.component';
import { ListaAlmacen } from './shared/components/Almacen/ListaAlmacen/ListaAlmacen';
import { FormaProductos } from './shared/components/Productos/forma/forma.component';
import { FormaAsignacionUsuariosPantallasComponent } from './shared/components/asignacion-usuarios-almacenes/forma/forma.component';
import { CrearListaComponent } from './shared/components/ListaDePrecios/CrearLista/crearLista.Component';
import { ComponentListaDePrecios } from './shared/components/ListaDePrecios/catalogo/catalogo.component';
import { FormaAltaDocumentosExcelComponent } from './shared/components/alta-documentos-excel/forma/forma.component';
import { AnalisisPfepComponent } from './shared/components/analisis-pfep/analisis-pfep.component';
import { FormaVentaComponent } from './shared/components/Ventas/forma/forma.component';
import { VentasCatalogo } from './shared/components/Ventas/catalogo/catalogo.component';
import { CatalogoConfiguracionPrincipalComponent } from './shared/components/configuracion/configuracion-principal/catalogo.component';
import { ReservasPfepComponent } from './shared/components/reservas-pfep/reservas-pfep.component';
import { CatalogoControlPedidosComponent } from './shared/components/control-pedidos/catalogo/catalogo.component';
import { FormaRegistroPedidosComponent } from './shared/components/control-pedidos/forma/catalogo.component';
import { PedidoResolver } from './services/resolver/pedido.resolver.service';
import { DdVentasGenerales } from './shared/components/db-ventas-generales/catalogo/catalogo.component';
import { CatalogoEmpresaProductoComponent } from './shared/components/asignacion-empresa-producto/catalogo/catalogo.component';
import { DocumentosExternosRequiciciones } from './core/login/components/documentos-externos/catalogo-requisiciones/catalogo.component';
import { DocumentosExternosEntradas } from './core/login/components/documentos-externos/catalogo/catalogo.component';
import { InventorikpiComponent } from './shared/components/kpis/inventory/catalogo.compoent';
import { ControlInventarioComponent } from './shared/components/control-inventario/catalogo.component';
import { CierreInventarioComponent } from './shared/components/cierre-inventario/cierre-inventario.component';
import { CatalogoReporteAntiguedadStock } from './shared/components/reporte-antiguedad-stock/catalogo/catalogo.component';
import { FormaDocPedidoComponent } from './shared/components/control-doc-pedido/forma/doc-pedido.component';
import { CatalogoControlPedidosSalidasComponent } from './shared/components/control-doc-pedido-salida/catalogo/catalogo.component';
import { FormaDocPedidoSalidaComponent } from './shared/components/control-doc-pedido-salida/forma/doc-pedido.component';
import { CatalogoLogSincronizacion } from './shared/components/reporte-sincronizacion/catalogo/catalogo.component';
import { CatalogoControlCierresComponent } from './shared/components/control-cierres/catalogo/catalogo.component';
import { CatalogoAsignacionProductoCritico } from './shared/components/asignacion-productos-criticos/catalogo/catalogo.component';
import { CatalogoControlPeriodosComponent } from './shared/components/control-periodos/catalogo/catalogo.component';
import { CatalogoCalcularConteo } from './shared/components/control-calcular-conteo/catalogo/catalogo.component';
import { CatalogoControlTraspasosComponent } from './shared/components/control-traspasos/catalogo/catalogo.component';
import { FormaDocTraspasoComponent } from './shared/components/control-doc-traspaso/doc-traspaso.component';
import { CatalogoReporteExistencias } from './shared/components/reporte-existencias/catalogo.component';
import { CatalogoFechaOperacion } from './shared/components/Fecha-de-operacion/catalogo/catalogo.component';
import { CatalogoFechaConteoMensual } from './shared/components/Fecha-de-operacion/fecha-conteo-mensual/Catalogo/catalogo.component';
import { CatalogoControlPeriodosSemanalComponent } from './shared/components/Fecha-de-operacion/periodos-semanales/catalogo/catalogo.component';
import { CatalogoControlTurnosConteosComponent } from './shared/components/Fecha-de-operacion/turnos-conteos/Catalogo/catalogo.component';
import { CatalogoMaquinariasComponent } from './shared/components/Maquinaria/catalogo/catalogo.component';
import { CatalogoControlDiasInhabilesComponent } from './shared/components/Fecha-de-operacion/dias-inhabiles/catalogo/catalogo.component';
import { CatalogoControlConteosPrductosComponent } from './shared/components/control-conteos-productos/catalogo.component';
import { CatalogoControlEntradasComponent } from './shared/components/control-entradas/catalogo/catalogo.component';
import { ComprobanteEntradaComponent } from './shared/components/control-entradas/comprobante-entrada/catalogo.component';
import { FormaDocEntradaComponent } from './shared/components/control-entradas/forma/doc-entrada.component';
import { CatalogoControlSalidasComponent } from './shared/components/control-salidas/catalogo/catalogo.component';
import { FormaDocSalidaComponent } from './shared/components/control-salidas/forma/doc-salida.component';



export const routes: Routes = [
  {
    path: 'Login',
    component: PaginaLoginComponent,
  },
  {
    path: 'DocumentosExternosRequisiciones',
    component: DocumentosExternosRequiciciones,
  },
  {
    path: 'DocumentosExternosEntradas',
    component: DocumentosExternosEntradas,
  },
  {
    path: 'Registro',
    component: PaginaRegistroClienteComponent,
  },
  {
    path: 'AccesoRestringido',
    component: CatalogoNoAccesoComponent,
  },

  {
    path: '',
    component: NavComponent,
    canActivate: [GuardiaAcceso],
    children: [
      {
        path: '',
        component: CatalogoDashBoardComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Usuarios',
        component: CatalogoUsuarioComponent,
        canActivate: [GuardiaMenuSeguridad],
      },
      {
        path: 'TiposUsuario',
        component: CatalogoTipoUsuarioComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Roles',
        component: CatalogoRolComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'TiposMenu',
        component: CatalogoTipoMenuComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Pantallas',
        component: CatalogoPantallaComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'AsignacionRolPantallas',
        component: FormaAsignacionRolPantallaComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ContactoSoporte',
        component: FormContactoSoporteComponent,
        canActivate: [GuardiaMenuCatalogos],
      },
      {
        path: 'ContactoSoporteLista',
        component: CatalogoContactoSoporteComponent,
        // canActivate: [GuardiaMenuCatalogos],
      },
      // Catalogos
      {
        path: 'GrupoProductos',
        component: CatalogoGrupoProductosComponent,
        canActivate: [GuardiaMenuCatalogos],
      },
      {
        path: 'AgregarNuevo',
        component: FormaGrupoProductoComponent,
      },
      {
        path: 'UnidadesMedida',
        component: CatalogoUnidadesMedida,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'EmpresaMinera',
        component: CatalogoEmpresaMinera,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Minas',
        component: CatalogoMinas,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Almacen',
        component: CatalogoAlmacen,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Productos',
        component: CatalogoProductos,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'Empleados',
        component: CatalogoEmpleados,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'MinasUbicaciones',
        component: CatalogoMinaUbicaciones,
      },
      {
        path: 'AlmacenesUsuarios',
        component: FormaAsignacionUsuariosPantallasComponent,
      },
      {
        path: 'AlmacenesProductos',
        component: CatalogoAsignacionAlmacenProducto,
      },
      {
        path: 'MinasEmpleados',
        component: CatalogoAsignacionMinaEmpleado,
      },
      {
        path: 'ArticuloAlternativo',
        component: CatalogoArticulosAlternativos,
      },
      {
        path: 'Reporteador',
        component: CatalogoReporteador,
      },
      {
        path: 'AnalisisPfep',
        component: AnalisisPfepComponent,
      },
      {
        path: 'ReservasPfep',
        component: ReservasPfepComponent,
      },
      {
        path: 'Requisiciones',
        component: CatalogoControlPedidosComponent,
      },
      {
        path: 'RequisicionSalida',
        component: CatalogoControlPedidosSalidasComponent,
      },
      {
        path: 'Registro-Pedidos-Salida/:id',
        component: FormaDocPedidoSalidaComponent,
      },
      {
        path: 'Registro-Pedidos/:id',
        component: FormaRegistroPedidosComponent,
        resolve: {
          pedido: PedidoResolver,
        },
      },
      {
        path: 'RegistroPedidos/:id',
        component: FormaDocPedidoComponent,
      },
      {
        path: 'Indicadores',
        component: CatalogoIndicadores,
      },
      {
        path: 'ListaAlternativos',
        component: FormaListaComponent,
        canDeactivate: [CanDeactiveGuard],
      },
      {
        path: 'TiposEstatus',
        component: CatalogoProcesoEstatus,
      },
      {
        path: 'ControlAjustes',
        component: CatalogoControlAjustes,
      },
      {
        path: 'AutorizarJuste/:id',
        component: FormaAutorizarAjuste,
      },
      {
        path: 'ListaAlmacen',
        component: ListaAlmacen,
      },
      {
        path: 'AltasProducto/:id',
        component: FormaProductos,
      },
      {
        path: 'ListaDePrecios',
        component: ComponentListaDePrecios,
      },
      {
        path: 'CrearLista',
        component: CrearListaComponent,
      },
      {
        path: 'AltaDocumentosExcel',
        component: FormaAltaDocumentosExcelComponent,
      },
      {
        path: 'ListaVentas',
        component: VentasCatalogo,
      },
      {
        path: 'CrearVentas',
        component: FormaVentaComponent,
      },
      {
        path: 'Configuracion',
        component: CatalogoConfiguracionPrincipalComponent,
      },
      {
        path: 'EmpresaProducto',
        component: CatalogoEmpresaProductoComponent,
      },
      {
        path: 'Inventario',
        component: InventorikpiComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'TablaInventario',
        component: ControlInventarioComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'CierreInventario',
        component: CierreInventarioComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ReporteAntiguedadStock',
        component: CatalogoReporteAntiguedadStock,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ReporteLogSincro',
        component: CatalogoLogSincronizacion,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ReporteCierre',
        component: CatalogoControlCierresComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ReporteConteos',
        component: CatalogoControlConteosPrductosComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ProductosCriticos',
        component: CatalogoAsignacionProductoCritico,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'PeriodosC',
        component:  CatalogoControlPeriodosComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'PeriodosSemanal',
        component:  CatalogoControlPeriodosSemanalComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'TurnosConteos',
        component:  CatalogoControlTurnosConteosComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'CalcularConteo',
        component: CatalogoCalcularConteo,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'TraspasoAlmacen',
        component: CatalogoControlTraspasosComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'RegistroTraspasos/:id',
        component: FormaDocTraspasoComponent,
      },
      {
        path: 'ReporteExistencia',
        component: CatalogoReporteExistencias,
      },
      {
        path: 'FechaOperacion',
        component: CatalogoFechaOperacion,
      },
      {
        path: 'FechaConteoMensual',
        component: CatalogoFechaConteoMensual,
      },
      {
        path: 'DiasInhabiles',
        component: CatalogoControlDiasInhabilesComponent,
      },
      {
        path: 'Maquinarias',
        component: CatalogoMaquinariasComponent,
        canActivate: [GuardiaAcceso],
      },
      {
        path: 'ControlEntradas',
        component: CatalogoControlEntradasComponent,
      },
      {
        path: 'RegistroEntradas/:id',
        component: FormaDocEntradaComponent,
      },
      {
        path: 'ComprobanteEntrada',
        component: ComprobanteEntradaComponent,
      },
      {
        path: 'ControlSalidas',
        component: CatalogoControlSalidasComponent,
      },
      {
        path: 'RegistroSalidas/:id',
        component: FormaDocSalidaComponent
      }

    ],
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  useHash: true,
});
