import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, Sort, MatDialog, } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { FormaMensajeComponent } from 'src/app/utilerias/mensaje-en-modal/forma/forma.component';
import { LoadingService } from 'src/app/loading/loading.service';
import { AsignacionSalidaPedido } from 'src/app/shared/models/asignacion-salida-pedido';
import { PedidoSalida } from 'src/app/shared/models/pedido-salida';
import { DetalleSalida } from 'src/app/shared/models/detalleSalidas';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Productos } from 'src/app/shared/models/Productos';
import { Empleados } from 'src/app/shared/models/Empleados';
import { Maquinarias } from 'src/app/shared/models/maquinarias';
import { Salida } from 'src/app/shared/models/Salida';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { ComprobanteSalidaComponent } from '../comprobante-salida/catalogo.component';

@AutoUnsubscribe()
@Component({
  templateUrl: './doc-salida.component.html',
  styleUrls: ['./doc-salida.component.scss'],
})
export class FormaDocSalidaComponent implements OnInit {
  forma: FormGroup;
  salidaId: number = 0;
  salida: Salida = new Salida();
  detalleSalida: DetalleSalida[] = [];
  pedidos: PedidoSalida[] = [];
  asignaciones: AsignacionSalidaPedido[] = [];
  contadorPedidoSelecionado: number = 1;
  detalle: DetalleSalida;
  detallePedidos: DetallePedido[] = [];
  textoBuscarEmpleado: string = '';
  textoBuscarMaquinaria: string = '';

  get f() {
    return this.forma.controls;
  }

  almacenId: number = 0;
  motivoId: number = 0;
  productos: Productos[] = [];
  empleados: Empleados[] = [];
  maquinarias: Maquinarias[] = [];
  motivos: number[] = [];
  descripcionMotivoLabel: string = '';
  almacenCodigo: string = '';

  textoBuscar: string = '';
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<DetalleSalida> = new MatTableDataSource([]);

  columnasMostradas = [
    'linea',
    'cantidad',
    'producto',
    'codigoProductoReemplazo',
    'orden',
    'unidadMedida',
    'existencia',
    'piezaPorEquipo',
    'taller',
    'tecnico',
    'fecha',
    'comentario',
    'opciones',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta,
    private activateRouter: ActivatedRoute,
    private db: Contexto,
    private autenticacion: AutenticacionService,
    private dialog: MatDialog,
    public ventana: MatDialog,
    private router: Router,
    private cargando: LoadingService
  ) {
    this.activateRouter.params.subscribe((params) => {
      this.salidaId = +params['id'];
    });
  }

  async ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [undefined, Validators.nullValidator],
      folio: ['', Validators.required],
      fechaDocumento: [new Date()],
      referencia: ['', Validators.nullValidator],
      motivo: [null, Validators.required],
      descripcionMotivo: ['', Validators.nullValidator],
      comentarios: ['', Validators.nullValidator],
      esBorrador: [false, Validators.required],
      empleadoId: [null, Validators.required],
      empleado: [null, Validators.required],
      almacenId: [0, Validators.nullValidator],
      firma: ['', Validators.nullValidator],
      nombreEmpleado: ['null', Validators.nullValidator],
      empleadoRecibe: ['', Validators.required],
      cantidadTotalSalida: [0, Validators.nullValidator],
      totalLineas: [0, Validators.nullValidator],
      ubicacionDescripcion: ['', Validators.nullValidator],
      almacenDescripcion: ['', Validators.nullValidator],
      cantidadTotalEntrada: [0, Validators.nullValidator],
    });
    //VALIDO QUE LA FECHA DE DOCUMENTO NO SEA MENOR A LA ULTIMA CREADA
    this.forma.get('fechaDocumento').valueChanges.subscribe(async () => {
      await this.asignarFechaValidacion();
    });

    this.forma.get('motivo').valueChanges.subscribe(value => {
      this.motivoId = value;
      this.updateDescripcionMotivoLabel(value);
    });

    this.almacenId = this.autenticacion.credencial.almacenId;

    await this.obtenerMotivos();
    await this.cargarEmpleados();
    await this.cargarProductos();
    await this.cargarMaquinarias();
    await this.recargarFormulario();

    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
  }

  async obtenerMotivos() {
    this.db.controlSalidas.ObtenerTipoMotivos().toPromise().then(resultado => {
      this.motivos = resultado;
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar los motivos');
      })
  }
  updateDescripcionMotivoLabel(motivoId: number) {
    if (motivoId == 1) {
      this.descripcionMotivoLabel = "Orden de Venta";
    } else if (motivoId == 2) {
      this.descripcionMotivoLabel = "Almacen de Destino";
    } else if (motivoId == 3) {
      this.descripcionMotivoLabel = "Motivo de Devolución";
    } else if (motivoId == 4) {
      this.descripcionMotivoLabel = "Descripción de los daños";
    } else if (motivoId == 5) {
      this.descripcionMotivoLabel = "Motivo de Ajuste";
    } else {
      this.descripcionMotivoLabel = 'Descripción del Motivo';
    }
  }

  async recargarFormulario() {
    if (this.salidaId == 0) {
      this.salida = await this.db.controlSalidas.CrearSalidaNueva(this.almacenId, this.autenticacion.credencial.usuarioId, this.autenticacion.credencial.nombreCompleto).toPromise();
      this.almacenCodigo = this.salida.almacenCodigo;
      Object.assign(this.forma.value, this.salida);
      this.forma.reset(this.forma.value);
      this.cargarDetalleSalida();
    } else {
      this.salida = await this.db.controlSalidas.ObtenerSalidaId(this.salidaId).toPromise();
      if (this.salida.asignacionPedidos != undefined || this.salida.asignacionPedidos.length > 0) {
        this.asignaciones = this.salida.asignacionPedidos;
      } else {
        this.asignaciones = null;
      }

      Object.assign(this.forma.value, this.salida);
      this.forma.reset(this.forma.value);
      this.cargarDetalleSalida();
    }
  }

  //#region VALIDAR FECHA DE DOCUMENTO
  async asignarFechaValidacion() {
    const ultimaFecha = await this.db.controlSalidas.ObtenerFechaUltimaSalida(this.almacenId).toPromise();
    const fecha = new Date(ultimaFecha['fechaDocumento']);
    const fechaControl = this.forma.get('fechaDocumento');
    fechaControl.setValidators([Validators.required, this.validarFecha(fecha)]);
  }

  validarFecha(ultimaFecha: Date) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let resultado = {
        fechaInvalida: `La fecha debe ser mayor ${ultimaFecha.toLocaleString()}`,
      };
      const valor = control.value;
      let fecha: Date;
      if (typeof valor === 'string') {
        fecha = new Date(valor);
      } else {
        fecha = valor;
      }
      if (!ultimaFecha) {
        resultado = null;
      } else if (fecha) {
        resultado = ultimaFecha < fecha ? null : resultado;
      }
      return resultado;
    };
  }
  //#endregion

  //#region CARGAR LOS DETALLES DE LA SALIDA
  cargarDetalleSalida() {
    this.detalleSalida = this.salida.detalles || [];
    if (this.detalleSalida.length == 0) {
      this.detalle = new DetalleSalida();
      this.detalle.id = undefined;
      this.detalle.linea = 1;
      this.detalle.cantidad = 0;
      this.detalle.cantidadRequisicion = 0;
      this.detalle.fechaSolicitud = new Date();
      this.detalle.orden = '';
      this.detalle.salidaId = undefined;
      this.detalle.productoCodigo;
      this.detalle.productoCodigoDescripcion = '';
      this.detalle.productoDescripcion = '';
      this.detalle.productoId = 0;
      this.detalle.unidadMedidaCodigo = '';
      this.detalle.codigoProductoReemplazo = '';
      this.detalle.existencia = 0;
      this.detalle.bloqueado = false;
      this.detalle.remarcar = false;
      this.detalle.piezaPorEquipo = '';
      this.detalle.comentario = '';
      this.detalle.tecnico = '';
      this.detalle.taller = '';
      this.detalle.maquinariaDescripcion = '';
      this.detalleSalida.push(this.detalle);

      this.fuenteDatos = new MatTableDataSource<DetalleSalida>(
        this.detalleSalida
      );
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.conteoLineasCantidad();
    } else {
      this.fuenteDatos = new MatTableDataSource<DetalleSalida>(
        this.detalleSalida
      );
      this.fuenteDatos.paginator = this.paginador;
      this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    }
  }

  //#endregion

  //#region  LIMPIAR TODO EL FORMULARIO

  limpiar(): void { }
  //#endregion
  //#region GUARDAR EL DOCUMENTO DE LA SALIDA
  async guardar(tipo: boolean) {
    //GUARDO EL PEDIDO COMO DOCUMENTO BORRADOR

    let estado = 1;
    const salida = this.forma.value as Salida;
    salida.fechaRegistro = new Date();
    salida.esBorrador = tipo;
    salida.detalles = this.detalleSalida;
    salida.asignacionPedidos = this.asignaciones;
    salida.usuarioNombreCompleto = this.autenticacion.credencial.nombreCompleto;
    salida.usuarioId = this.autenticacion.credencial.usuarioId;

    if (salida.asignacionPedidos && salida.asignacionPedidos.length > 0) {
      const faltante = salida.detalles.map((e) => {
        let falta = e.cantidadRequisicion - e.cantidad;
        falta = falta < 0 ? 0 : falta;
        return falta;
      })
        .reduce((a, b) => a + b);

      //PREGUNTO SI QUIERE QUE SE CIERREN LAS REQUISICIONES O SE QUEDEN ABIERTAS
      if (faltante > 0) {
        estado = await this.mensaje(
          'Aún cuenta con detalle faltante. ' +
          '¿Comó desea guardar las requisiciones incompletas?',
          false,
          false,
          false,
          true,
          true
        );
      } else {
        estado = 2;
      }
      if (estado === 4) {
        return;
      }
    }





    this.cargando.show('Espera un momento... Generando una nueva salida');
    const accion =
      this.salida.id > 0
        ? this.db.controlSalidas.ModificarSalidaBD(salida)
        : this.db.controlSalidas.EnviarSalidaBD(salida);

    accion.toPromise()
      .then(() => {
        this.cargando.hide();
        this.alerta.mostrarExito('Documento guardado');
        this.router.navigate(['ControlSalidas']);
        if (salida.esBorrador == false) {
          this.db.controlSalidas.RestarAlInventario(salida).toPromise();
          this.db.controlSalidas.ModificarPedido(salida, estado).toPromise();
        }
      })
      .catch((e) => {
        this.cargando.hide();
        this.alerta.mostrarError('Error al guardar');
      });

  }
  //#endregion

  //#region BUSCAR EL PRODUCTO DENTRO DEL DETALLE
  buscar(event) {
    this.textoBuscar = event.target.value;
  }

  //#region BUSCAR EL PRODUCTO DENTRO DEL DETALLE
  buscar2(event) {
    this.textoBuscar = event.target.value;
  }


  //#endregion

  //#region ACTUALIZAR LA LINEA O EL ROW DEL DETALLE
  async actualizaLinea(
    linea: number,
    cantidad?: number,
    orden?: string,
    fecha?: Date,
    producto?: Productos,
    comentario?: string,
    piezaPorEquipo?: Maquinarias,
    taller?: string,
    tecnico?: string
  ) {

    const detalle = this.fuenteDatos.data.find((e) => e.linea == linea);
    detalle.id = undefined;
    detalle.linea = linea;
    detalle.cantidad = cantidad == undefined ? 0 : +cantidad;
    detalle.salidaId = undefined;
    detalle.comentario = comentario;
    detalle.taller = taller;
    detalle.tecnico = tecnico;
    if (producto.id > 0) {
      detalle.productoCodigo = producto.codigo;
      detalle.productoDescripcion = producto.productoDescripcion;
      detalle.productoCodigoDescripcion = producto.productoCodigoDescripcion;
      detalle.productoId = producto.id;
      detalle.unidadMedidaCodigo = producto.unidadMedidaDescripcion;
      detalle.sku = producto.sku;
      detalle.otro = producto.otro;
      detalle.material = producto.material;
      detalle.codigoProductoReemplazo = producto.codigoProductoReemplazo;
      const inventario = await this.db.controlEntradas.ObtenerInventario(this.autenticacion.credencial.almacenId, detalle.productoId).toPromise();
      detalle.existencia = inventario.length > 0 ? inventario[0].cantidad : 0;
    }
    if (piezaPorEquipo.id > 0) {
      detalle.maquinariaId = piezaPorEquipo.id;
      detalle.maquinariaDescripcion = piezaPorEquipo.descripcion;
    }
    if (detalle.esRequisicion) {
      detalle.fechaSolicitud = detalle.fechaSolicitud;
      detalle.cantidadRequisicion = detalle.cantidadRequisicion;
      detalle.orden = detalle.orden;
      detalle.bloqueado = true;
    } else {
      detalle.fechaSolicitud = fecha;
      detalle.cantidadRequisicion = 0;
      detalle.orden = orden;
      detalle.bloqueado = false;
    }
    detalle.remarcar = false;
    this.conteoLineasCantidad();
  }
  //#endregion


  //#region AGREGA NUEVA LINEA AL DETALLE Y A LA TABLA
  agregarLinea() {
    const detalle = new DetalleSalida();
    detalle.id = undefined;
    detalle.linea = this.detalleSalida.length + 1;
    detalle.cantidad = 0;
    detalle.fechaSolicitud = new Date();
    detalle.orden = '';
    detalle.productoCodigo = '';
    detalle.productoDescripcion = '';
    detalle.productoCodigoDescripcion = '';
    detalle.productoId = 0;
    detalle.bloqueado = false;
    detalle.cantidadRequisicion = 0;
    detalle.salidaId = undefined;
    detalle.remarcar = false;
    detalle.maquinariaDescripcion = '';
    detalle.unidadMedidaCodigo = '';
    detalle.codigoProductoReemplazo = '';
    detalle.piezaPorEquipo = '';
    detalle.comentario = '';
    detalle.existencia = 0;
    detalle.tecnico = '';
    detalle.taller = '';

    this.detalleSalida.push(detalle);
    this.fuenteDatos = new MatTableDataSource(this.detalleSalida);
    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
    this.ordenadoPorLineaDesc();
    this.conteoLineasCantidad();
    this.textoBuscar = '';
  }
  //#endregion
  //#region REORDENA LA LISTA POR NUMERO DE LINEA DE MENOR A MAYOR
  ordenadoPorLineaDesc() {
    const sortState: Sort = { active: 'linea', direction: 'desc' };
    this.ordenador.active = sortState.active;
    this.ordenador.direction = sortState.direction;
    this.ordenador.sortChange.emit(sortState);
  }
  //#endregion
  //#region IDENTIFICA LA CANTIDAD DE LINEAS Y PRODUCTOS RECIBIDOS
  conteoLineasCantidad() {
    this.f['cantidadTotalEntrada'].setValue(0);
    this.f['totalLineas'].setValue(0);

    let totalEntrada = this.fuenteDatos.data.reduce((suma, detalle) => suma + +detalle.cantidad, 0);
    let totalRequisicion = this.fuenteDatos.data.reduce((suma, detalle) => suma + +detalle.cantidadRequisicion, 0);
    this.f['cantidadTotalEntrada'].setValue(totalEntrada.toFixed(2));
    this.f['totalLineas'].setValue(this.fuenteDatos.data.length);
  }
  //#endregion

  //#region ELIMINA LA LINEA DEL DETALLE Y DE LA TABLA, REORDENA Y REALIZA EL CONTEO DE LAS CANTIDADES DE LA LINEA
  removerLinea(linea: number) {
    if (this.detalleSalida.length > 1) {
      this.detalleSalida.splice(linea - 1, 1);
      let contador: number = 1;
      for (let index = 0; index < this.detalleSalida.length; index++) {
        const element = this.detalleSalida[index];
        element.linea = contador;
        contador++;
      }
      this.fuenteDatos = new MatTableDataSource(this.detalleSalida);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    } else {
      this.limpiarProductos(linea);
    }
  }
  //#endregion

  //#region  PRODUCTOS EN DETALLE

  async cargarProductos() {
    this.productos = await this.db.controlEntradas.ObtenerListaProductosPorAlmacen(this.almacenId).toPromise();
  }


  //LIMPIA LA LINEA DEL DETALLE Y LA TABLA
  async limpiarProductos(linea: number) {
    const detalle = this.fuenteDatos.data.find((e) => e.linea == linea);
    if (!detalle.bloqueado) {
      detalle.id = undefined;
      detalle.cantidad = 0;
      detalle.fechaSolicitud = new Date();
      detalle.orden = '';
      detalle.productoCodigo = '';
      detalle.productoDescripcion = '';
      detalle.productoCodigoDescripcion = '';
      detalle.productoId = 0;
      detalle.bloqueado = false;
      detalle.cantidadRequisicion = 0;
      detalle.salidaId = undefined;
      detalle.remarcar = false;
      detalle.unidadMedidaCodigo = '';
      detalle.codigoProductoReemplazo = '';
      detalle.piezaPorEquipo = '';
      detalle.comentario = '';
      this.textoBuscar = '';
      detalle.existencia = 0;
      detalle.taller = '';
      detalle.tecnico = '';
      detalle.maquinariaId = 0;
      detalle.maquinariaDescripcion = '';
    }
  }

  //#endregion


  //LIMPIAR MAQUINARIA

  async limpiarMaquinaria(linea: number) {
    const detalle = this.fuenteDatos.data.find((e) => e.linea == linea);
    if (!detalle.bloqueado) {
      this.textoBuscar = '';
      detalle.maquinariaId = null;
      detalle.maquinariaDescripcion = '';
    }
  }



  //#region METODOS PARA LIMPIAR

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  filtrar(filtro: string) {
    this.fuenteDatos.filter = filtro;
  }
  //#endregion

  //MENSAJE AYUDA A IDENTIFICAR LAS RESPUESTAS Y ASIGNA LA ACCION CORRESPONDIENTE
  async mensaje(
    mensaje: string,
    mostrarCargando?: boolean,
    botonOk?: boolean,
    botonCancelar?: boolean,
    botonAbierto?: boolean,
    botonCerrado?: boolean
  ): Promise<number> {
    let respuesta = 0;
    const dato = {
      mensaje,
      mostrarCargando,
      botonOk,
      botonCancelar,
      botonAbierto,
      botonCerrado,
    };
    const forma = this.ventana.open(FormaMensajeComponent, {
      data: dato,
      panelClass: 'form-container',
    });
    respuesta = await forma.afterClosed().toPromise();
    return respuesta;
  }

  //Region MAQUINARIA Y SUS EVENTOS
  buscarMaquinaria(event) {
    this.textoBuscarMaquinaria = event.target.value;
  }
  async cargarMaquinarias(): Promise<void> {
    this.db.Maquinarias.obtenerMaquinariasPorAlmacen(this.almacenId).toPromise().then((ps) => {
      this.maquinarias = ps;
    });
  }


  //#region EMPLEADOS Y SUS EVENTOS
  buscarEmpleado(event) {
    this.textoBuscarEmpleado = event.target.value;
  }

  limpiarEmpleadoSeleccion(): void {
    this.forma.get('empleadoId').setValue(null);
    this.forma.get('empleado').setValue('');
  }

  async cargarEmpleados() {
    this.empleados = await this.db.controlSalidas.ObtenerListaEmpleados(this.almacenId).toPromise();
  }

  seleccionarEmpleado(empleado: Empleados) {
    this.forma.get('empleadoId').setValue(empleado.id);
    this.forma.get('empleado').setValue(empleado.nombreCompleto);
  }
  //#endregion

  //#region SOLICITAR FIRMA DEL EMPLEADO PARA LA SALIDA DEL ITEM
  solicitarFirma() {
    const dialogRef = this.dialog.open(ComprobanteSalidaComponent, {
      data: { salida: this.salida, soloLectura: false },
      panelClass: 'comprobante',
    });

    dialogRef
      .afterClosed()
      .subscribe((resultado: { acepto: boolean; firma: string }) => {
        if (resultado.acepto) {
          this.forma.get('firma').setValue(resultado.firma);
          this.guardar(false);
        }
      });
  }

  async verificarDocumentPorGuardar() {
    if (!this.forma.valid) {
      this.alerta.mostrarAdvertencia('Hay campos requeridos o pendientes de capturar!');
      return;
    } else {

      this.salida = this.forma.value as Salida;
      this.salida.fechaRegistro = new Date();
      this.salida.esBorrador = false;
      this.salida.detalles = this.detalleSalida;
      this.salida.asignacionPedidos = this.asignaciones;
      this.salida.almacenCodigo = this.almacenCodigo; //todo
      this.salida.nombreEmpleado = this.forma.get('empleado').value;

      if (this.motivoId == 0) {
        this.alerta.mostrarAdvertencia(
          '¡No esta seleccionando un motivo!'
        );
        return;
      }

      //RECALCO SI NO SE SELECCIONO MAQUINARIA
      var maquinariaEnCero = this.detalleSalida
        .filter((e) => e.maquinariaId == 0 || e.maquinariaId == undefined || e.maquinariaDescripcion == "")
        .map((e) => (e.remarcar = true));

      if (maquinariaEnCero.length > 0) {
        this.alerta.mostrarAdvertencia(
          '¡No esta seleccionando maquinaria!'
        );
        return;
      }


      //VALIDO QUE NINGUNA LINEA SE VAYA EN PRODUCTO 0 ---> NULL
      var productoNull = this.detalleSalida
        .filter((e) => e.productoId == 0 || e.productoCodigo == "")
        .map((e) => (e.remarcar = true));

      if (productoNull.length > 0) {
        this.alerta.mostrarAdvertencia(
          '¡Aún hay productos por capturar!'
        );
        return;
      }

      let cantidadexcedida = this.detalleSalida.filter((e) => e.cantidad > e.existencia);
      if (cantidadexcedida.length > 0) {
        this.alerta.mostrarAdvertencia('!Hay cantidades de productos que exceden la existencia del inventario!');
        this.detalleSalida.filter((e) => e.cantidad > e.existencia).map((e) => (e.remarcar = true));
        return;
      } else {
        this.solicitarFirma();
      }
      //}
    }
  }
}
