import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cierre } from '../models/cierres';
import { DetalleCierres } from '../models/detalle-cierres';
import { ExcelDetalleCierre } from '../models/excel-detalle-cierre';
import { ConteosProductos } from '../models/conteos-productos';
import { ConteoProducto } from '../models/conteoProducto';
import { PeriodosSemanas } from '../models/periodo-semanas';
import { TurnoConteo } from '../models/turno-conteo';
import { Inventario } from '../models/Inventario';
import { Producto } from '../models/producto';
import { InventarioConteo } from '../models/inventario-conteo';
import { ConteoSemanal } from '../models/conteo-semanal';
import { Respuesta } from '../models/respuesta';

export class RepositorioConteosProductos extends Repositorio<ConteosProductos> {
  constructor(http: HttpClient) {
    super(http, 'Conteo');
  }

  //   obtenerExcel(id: number): Observable<Blob> {
  //     const ruta = `${this.ruta}/DescargarExcel`;
  //     return this.http
  //       .post(ruta, id, {
  //         responseType: 'blob' as 'blob',
  //       })
  //       .pipe(
  //         map((res: Blob) => {
  //           return res;
  //         })
  //       );
  //   }

  ObtenerConteosAlmacen(almacenId: number): Observable<ConteosProductos[]> {
    const ruta = `${this.ruta}/ObtenerConteosAlmacen/${almacenId}`;
    return this.http.get<ConteosProductos[]>(ruta);
  }

  ObtenerConteosSemanal(almacenId: number): Observable<ConteoSemanal[]> {
    const ruta = `${this.ruta}/ObtenerConteosSemanal/${almacenId}`;
    return this.http.get<ConteoSemanal[]>(ruta);
  }

  ObtenerDiaConteoSemanal(almacenId: number): Observable<PeriodosSemanas[]> {
    const ruta = `${this.ruta}/ObtenerPeriodosSemanal/${almacenId}`;
    return this.http.get<PeriodosSemanas[]>(ruta);
  }

  ObtenerInventario(almacenId: number, productoId: number): Observable<Inventario[]> {
    const ruta = `${this.ruta}/ObtenerInventario/${almacenId}/${productoId}`;
    return this.http.get<Inventario[]>(ruta);
  }


  ObtenerMovimientosArticulos(almacenId: number, fechaInicial: string, fechaFinal: string): Observable<InventarioConteo[]> {
    const ruta = `${this.ruta}/ObtenerMovimientosArticulos/${almacenId}/${fechaInicial}/${fechaFinal}`;
    return this.http.get<InventarioConteo[]>(ruta);
  }

  ObtenerUltimoConteo(almacenId: number): Observable<Boolean> {
    const ruta = `${this.ruta}/ObtenerUltimoConteo/${almacenId}`;
    return this.http.get<Boolean>(ruta);
  }

  GuardarConteoSemanal(conteo: ConteoSemanal): Observable<Respuesta<ConteoSemanal>> {
    const ruta = `${this.ruta}/GuardarConteoSemanal`;
    return this.http.post<Respuesta<ConteoSemanal>>(ruta, conteo);
  }

  //   ObtenerDetalles(id: number): Observable<DetalleCierres[]> {
  //     const ruta = `${this.ruta}/ObtenerDetalleCierres/${id}`;
  //     return this.http.get<DetalleCierres[]>(ruta);
  //   }f

  //   obtenerCierreDetalle(id: number): Observable<Cierre> {
  //     const ruta = `${this.ruta}/ObtenerCierreDetalle/${id}`;
  //     return this.http.get<Cierre>(ruta);
  //   }

  ObtenerComprobanteConteo(id: number): Observable<ConteosProductos> {
    const ruta = `${this.ruta}/ObtenerComprobanteConteo/${id}`;
    return this.http.get<ConteosProductos>(ruta);
  }

  enviarCoteoSemanal(conteoSemanal: ConteoProducto | unknown): Observable<Respuesta<ConteoSemanal>> {
    const ruta = `${this.ruta}/EnviarNotificacionConteoSemanal`;
    return this.http.post<Respuesta<ConteoSemanal>>(ruta, conteoSemanal);
  }

  //   cargarDetalleCierresExcel(items: ExcelDetalleCierre[]): Observable<ExcelDetalleCierre[]> {

  //     let ruta = `${this.ruta}/CargarDetalleCierreExcel`;
  //     return this.http.post(ruta, items).pipe(
  //       map((articulos: ExcelDetalleCierre[]) => {
  //         return articulos;
  //       })
  //     );
  //   }

}
