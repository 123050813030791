import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajustes } from '../models/Ajustes';
import { DetalleAjustes } from '../models/detalle-ajustes';

export class RepositorioControlAjuste extends Repositorio<Ajustes> {
  constructor(http: HttpClient) {
    super(http, 'ControlAjuste');
  }

  obtenerDetalleAjuste(ajusteId: number): Observable<DetalleAjustes[]> {
    const ruta = `${this.ruta}/ObtenerDetalleAjuste/${ajusteId}`;
    return this.http.get<DetalleAjustes[]>(ruta);
  }

  obtenerAjuste(ajusteId: number): Observable<Ajustes> {
    const ruta = `${this.ruta}/ObtenerAjuste/${ajusteId}`;
    return this.http.get<Ajustes>(ruta);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerAjustesAlmacen(almacenId: number): Observable<Ajustes[]> {
    const ruta = `${this.ruta}/ObtenerAjustesAlmacen/${almacenId}`;
    return this.http.get<Ajustes[]>(ruta);
  }
}
