import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Pedido } from 'src/app/shared/models/pedido';
import { MatTableDataSource, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';

@Component({
  selector: 'app-lista-pedidos',
  templateUrl: './lista-pedidos.component.html',
  styleUrls: ['./lista-pedidos.component.scss'],
})
export class ListaPedidosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  sub: Subscription;
  fuenteDatos = new MatTableDataSource<Pedido>([]);
  seleccion = new SelectionModel<Pedido>(true, []);
  seleccionados = new SelectionModel<Pedido>(true, []);
  columnas = ['select', 'folio', 'referencia', 'fechaDocumento'];

  constructor(
    private db: Contexto,
    @Inject(MAT_DIALOG_DATA) public dato: Pedido[],
    public autenticacion: AutenticacionService,
    private alerta: ServicioAlerta,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
  ) { }

  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.fuenteDatos.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.seleccion.clear()
      : this.fuenteDatos.data.forEach((row) => this.seleccion.select(row));
  }
  cargarPedidos() {
    this.db.controlEntradas.cargarPedidos(this.autenticacion.credencial.almacenId).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;

      if (this.dato && this.dato.length > 0) {
        this.fuenteDatos.data.forEach(pedido1 => {
          this.dato.forEach(pedido2 => {
            if (pedido1.id == pedido2.id) {
              this.seleccion.toggle(pedido1);
              this.seleccion.select(pedido1);
              this.seleccion.isSelected(pedido1);
            }
          });
        });
      }
    }).catch(e => {
      this.alerta.mostrarError('Error al cargar las entradas de inventario');
    });

  }

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarPedidos();
      }
    );
  }
}
