import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Entrada } from 'src/app/shared/models/entrada';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { DetalleEntrada } from 'src/app/shared/models/detalle-entrada';
import { Subscription } from 'rxjs';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-entrada',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ImprimirComprobanteEntradaComponent implements OnInit {
  columnas = ['Codigo', 'ProductoDescripcion', 'Material', 'Cantidad', 'CantidadRequisicion', 'Ubicacion', 'Orden', 'FechaSolicitud'];
  entrada: Entrada = new Entrada();
  cantidadTotal: number = 0;
  cantidadLineas: any;
  fechaLocal: Date = new Date();
  folio: string = "";
  fechaDocumento: string = "";
  minaDescripcion: string = "";
  sub: Subscription;
  fuenteDatos: MatTableDataSource<DetalleEntrada> = new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ImprimirComprobanteEntradaComponent>,
    @Inject(MAT_DIALOG_DATA) public ent: Entrada,
    private exportAsService: ExportAsService,
    public autenticacion: AutenticacionService,
    private db: Contexto,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    private alerta: ServicioAlerta,
  ) {

    this.entrada = ent[0];
    this.folio = "- Folío: " + " - " + this.entrada.folio.toString() + " - ";
    this.fechaDocumento = "- Fecha de documento: " + " - " + new Date(this.entrada.fechaDocumento).toISOString().slice(0, 10) + " - ";
    this.minaDescripcion = this.entrada.minaDescripcion.toString();

  }

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );
  }

  cargarTabla(): void {
    this.db.controlEntradas.ObtenerDetalleEntradaId(this.entrada.id).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cantidadLineas = this.fuenteDatos.filteredData.length;
      this.fuenteDatos.filteredData.forEach(element => {
        this.cantidadTotal += element.cantidad
      });
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar los detalles de la entrada');
      })
  }

  regresar() {
    this.dialogRef.close();
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Entrada ' + this.entrada.folio + '.pdf').subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Entrada ' + this.entrada.folio + '.xlsx').subscribe();
  }

  imprimirPDF() {

  }
}
