import { Documento } from './documento';
import { DetalleEntrada } from './detalle-entrada';
import { AsignacionEntradaPedido } from './asignacion-entrada-pedido';

export class Entrada extends Documento {
  fechaRecepcion: Date;
  detalles: DetalleEntrada[] = [];
  asignacionPedidos: AsignacionEntradaPedido[] = [];
  qty: number;
  esLocal: boolean;
  estado: number;
  motivo: number;
  descripcionMotivo: string;
}
