import { Documento } from './documento';
import { DetalleSalida } from './detalleSalidas';
import { AsignacionSalidaPedido } from './asignacion-salida-pedido';

export class Salida extends Documento {
  fechaRecepcion: Date;
  empleadoId = 0;
  nombreEmpleado = '';
  empleadoRecibe = '';
  //firma = '';
  tiposStatusId: number;
  detalles: DetalleSalida[] = [];
  asignacionPedidos: AsignacionSalidaPedido[] = [];
  qty: number;
  esLocal: boolean;
  estado: number;
  motivo: number;
  descripcionMotivo: string;
}
