import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { Ajustes } from 'src/app/shared/models/Ajustes';
import { Router } from '@angular/router';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'control-ajustes',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoControlAjustes implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  sub: Subscription;

  fuenteDatos: MatTableDataSource<Ajustes> = new MatTableDataSource([]);
  columnasMostradas = ['minaDescripcion','ubicacionDescripcion', 'almacenDescripcion', 'fechaRegistro', 'folio', 'usuarioNombreCompleto', 'estatusDescripcion', 'estatusDocumento', 'opciones'];

  constructor(  public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService, public ventana: MatDialog, private ctx: Contexto, private spinner: LoadingService, private alerta: ServicioAlerta, private router: Router) { }

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.tabla();
      }
    );

  }


  tabla(): void {
    this.ctx.controlAjustes.obtenerAjustesAlmacen(this.autenticacion.credencial.almacenId).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar los ajustes no autorizados');
      })
  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "Reporte hasta el día: " + fechaActual.toLocaleString();
    this.ctx.controlAjustes.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  abrirForma(id?: string): void {
    let dato = id ? id : null;
    this.router.navigate(['/AutorizarJuste/', dato]);
  }

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }

}
