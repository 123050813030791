import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import 'hammerjs';
import { AppComponent } from './app.component';

import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  CommonModule,
} from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatTabsModule,
  MatCheckboxModule,
  MatMenuModule,
  MatGridListModule,
  MatTooltipModule,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatRadioModule,
  MatExpansionModule,
  MatCardModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MAT_DATE_LOCALE,
  MatBadgeModule,
  MatProgressBarModule,
  DateAdapter,
  MAT_DATE_FORMATS
} from '@angular/material';
import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { NavComponent } from './core/nav/nav.component';
import { PaginaLoginComponent } from './core/login/components/pagina/pagina.component';
import { routing } from './app.routing';
import { CusPageModule } from 'src/page/page.module';
import { LigaSeguraPipe } from './utilerias/liga-segura.pipe';
import { CusCardModule } from 'src/card/card.module';
import { NgxsStoreModule } from './services/theming/store.module';
import { CatalogoUsuarioComponent } from './shared/components/usuarios/catalogo/catalogo.component';
import { FormaUsuarioComponent } from './shared/components/usuarios/forma/forma.component';
import { CatalogoTipoUsuarioComponent } from './shared/components/tipo-usuarios/catalogo/catalogo.component';
import { FormaTipoUsuarioComponent } from './shared/components/tipo-usuarios/forma/forma.component';
import { CatalogoRolComponent } from './shared/components/rol/catalogo/catalogo.component';
import { FormaRolesUsuarioComponent } from './shared/components/rol-usuario/forma/forma.component';
import { CatalogoDashBoardComponent } from './shared/components/dashboard/catalogo/catalogo.component';
import { CatalogoPantallaComponent } from './shared/components/pantallas/catalogo/catalogo.component';
import { CatalogoTipoMenuComponent } from './shared/components/tipo-menus/catalogo/catalogo.component';
import { FormaTipoMenuComponent } from './shared/components/tipo-menus/forma/forma.component';
import { FormaPantallaComponent } from './shared/components/pantallas/forma/forma.component';
import { FormaAsignacionRolPantallaComponent } from './shared/components/asignacion-rol-pantalla/forma/forma.component';
import { CatalogoGrupoProductosComponent } from './shared/components/grupo-productos/catalogo/catalogo.component';
import { FormaGrupoProductoComponent } from './shared/components/grupo-productos/forma/forma.component';
import { PaginaRegistroClienteComponent } from './core/login/components/registro-cliente/pagina.component';
import { MaskTelefonoDirective } from './utilerias/mask-telefono.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadComponent } from './loading/loading.component';
import { LoadingService } from './loading/loading.service';
import { FormContactoSoporteComponent } from './shared/components/contacto-soporte/forma/forma.component';
import { CatalogoContactoSoporteComponent } from './shared/components/contacto-soporte/catalogo/catalogo.component';
import { HostService } from './services/infoHost/host-service.service';
import { WINDOW_PROVIDERS } from './services/infoHost/window.provider';
import { CatalogoNoAccesoComponent } from './shared/components/no-acceso/catalogo/catalogo.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AccesoTokenInterceptor } from './core/services/acceso-token.interceptor';
import { ChartsModule } from 'ng2-charts';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { CatalogoUnidadesMedida } from './shared/components/UnidadesMedida/catalogo/catalogo.component';
import { FormaUnidadesMedida } from './shared/components/UnidadesMedida/forma/forma.component';
import { CatalogoEmpresaMinera } from './shared/components/empresaMinera/catalogo/catalogo.component';
import { FormaEmpresaMinera } from './shared/components/empresaMinera/forma/forma.component';
import { CatalogoAlmacen } from './shared/components/Almacen/catalogo/catalogo.component';
import { FormaAlmacen } from './shared/components/Almacen/forma/forma.component';
import { CatalogoMinas } from './shared/components/Minas/catalogo/catalogo.component';
import { FormaMina } from './shared/components/Minas/forma/forma.component';
import { CatalogoProductos } from './shared/components/Productos/catalogo/catalogo.component';
import { FormaProductos } from './shared/components/Productos/forma/forma.component';
import { CatalogoEmpleados } from './shared/components/Empleados/catalogo/catalogo.component';
import { FormaEmpleados } from './shared/components/Empleados/forma/forma.component';
import { CatalogoMinaUbicaciones } from './shared/components/mina-ubicaciones/catalogo/catalogo.component';
import { FormaMinaUbicaciones } from './shared/components/mina-ubicaciones/forma/forma.component';
import { CatalogoAsignacionAlmacenProducto } from './shared/components/asignacion-usuario-producto/catalogo/catalogo.component';
import { FormaAsignacionAlmacenProducto } from './shared/components/asignacion-usuario-producto/forma/forma.component';
import { FormaAsignacionMinaEmpleado } from './shared/components/asignacion-mina-empleado/forma/forma.component';
import { CatalogoAsignacionMinaEmpleado } from './shared/components/asignacion-mina-empleado/catalogo/catalogo.component';
import { CatalogoArticulosAlternativos } from './shared/components/articulos-alternativos/catalogo/catalogo.component';
import { FormaArticuloAlternativo } from './shared/components/articulos-alternativos/forma/forma.component';
import { CatalogoReporteador } from './shared/components/reporteador/catalogo/catalogo.component';
import { CatalogoTopProductos } from './shared/components/Inidicadores/TopProductos/catalogo.component';
import { CatalogoInventario } from './shared/components/Inidicadores/Inventario/catalogo.component';
import { CatalogoIndicadores } from './shared/components/Inidicadores/catalogo.component';
import { FormaListaComponent } from './shared/components/articulos-alternativos/formaLista/formaLista.component';
import { CatalogoProcesoEstatus } from './shared/components/ProcesoEstatus/catalogo/catalogo.component';
import { FormaProcesoEstatus } from './shared/components/ProcesoEstatus/forma/forma.component';
import { CatalogoControlAjustes } from './shared/components/control-ajustes/catalogo/catalogo.component';
import { FormaAutorizarAjuste } from './shared/components/control-ajustes/forma/forma.component';
import { FormaMensajeComponent } from './utilerias/mensaje-en-modal/forma/forma.component';
import { ListaAlmacen } from './shared/components/Almacen/ListaAlmacen/ListaAlmacen';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormaAsignacionUsuariosPantallasComponent } from './shared/components/asignacion-usuarios-almacenes/forma/forma.component';
import { FormaListaDePrecios } from './shared/components/ListaDePrecios/forma/forma.component';
import { CrearListaComponent } from './shared/components/ListaDePrecios/CrearLista/crearLista.Component';
import { ComponentListaDePrecios } from './shared/components/ListaDePrecios/catalogo/catalogo.component';
import { FormaAltaDocumentosExcelComponent } from './shared/components/alta-documentos-excel/forma/forma.component';
import { AnalisisPfepComponent } from './shared/components/analisis-pfep/analisis-pfep.component';
import { RequisicionPfepComponent } from './shared/components/requisicion-pfep/requisicion-pfep.component';
import { agregarListaComponent } from './shared/components/Almacen/AgregarLista/agregarLista.component';
import { preciosAlmacenesComponent } from './shared/components/ListaDePrecios/PreciosAlmacenes/preciosAlmacenes.component';
import { SeleccionesComponent } from './shared/components/Ventas/selecciones/selecciones.component';
import { VentasCatalogo } from './shared/components/Ventas/catalogo/catalogo.component';
import { FormaVentaComponent } from './shared/components/Ventas/forma/forma.component';
import { DetallesComponent } from './shared/components/Ventas/detalles/detalles.component';
import { ReservasPfepComponent } from './shared/components/reservas-pfep/reservas-pfep.component';
import { CatalogoConfiguracionPrincipalComponent } from './shared/components/configuracion/configuracion-principal/catalogo.component';
import { CatalogoParametrosPFEPComponent } from './shared/components/configuracion/parametros PFEP/catalogo.component';
import { CatalogoControlPedidosComponent } from './shared/components/control-pedidos/catalogo/catalogo.component';
import { ComprobantePedidoComponent} from './shared/components/comprobante-pedido/catalogo.component';
import { FormaRegistroPedidosComponent } from './shared/components/control-pedidos/forma/catalogo.component';
import { ListaDetallesPedidoComponent } from './shared/components/control-pedidos/lista-productos/lista-productos.component';
import { DetallePedidoComponent } from './shared/components/control-pedidos/producto/producto.component';
import { EncabezadoPedidoComponent } from './shared/components/control-pedidos/encabezado/encabezado.component';
import { CatalogoEntradaExcelComponent } from './shared/components/alta-documentos-excel/catalogo-requisiciones-excel/catalogo.component';
import { CatalogoEsquemaDesktop } from './shared/components/configuracion/esquema-desktop/catalogo.component';
import { FormaProductoComponent } from './shared/components/asignacion-empresa-producto/forma-producto/forma-producto.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormaAsignacionEmpresaProductoComponent } from './shared/components/asignacion-empresa-producto/forma/forma.component';
import { CatalogoEmpresaProductoComponent } from './shared/components/asignacion-empresa-producto/catalogo/catalogo.component';
import { DdVentasGenerales } from './shared/components/db-ventas-generales/catalogo/catalogo.component';
import { DdProductoConMenosMovimientos } from './shared/components/dashboard/producto-rezagado/catalogo/catalogo.component';
import { DdControlRecepciones } from './shared/components/dashboard/control-recepciones/catalogo/catalogo.component';
import { DdEficiencia } from './shared/components/dashboard/eficiencia/catalogo/catalogo.component';
import { DocumentosExternosRequiciciones } from './core/login/components/documentos-externos/catalogo-requisiciones/catalogo.component';
import { DocumentosExternosEntradas } from './core/login/components/documentos-externos/catalogo/catalogo.component';
import { InventorikpiComponent } from './shared/components/kpis/inventory/catalogo.compoent';
import { ControlInventarioComponent } from './shared/components/control-inventario/catalogo.component';
import { ParetoComponent } from './shared/components/kpis/pareto/pareto.component';
import { CatalogoAdministradorCorreos } from './shared/components/configuracion/administrador-correos/catalogo/catalogo.component';
import { FormaAdministradorCorreos } from './shared/components/configuracion/administrador-correos/forma/forma.component';
import { CatalogoTemporizadorNotificaciones } from './shared/components/configuracion/temporizador-notificaciones/catalogo/catalogo.component';
import { FormaTemporizadorNotificaciones } from './shared/components/configuracion/temporizador-notificaciones/forma/forma.component';
import { FormaProductoKardex } from './shared/components/dashboard/producto-rezagado/forma-producto-kardex/forma.component';
import { CierreInventarioComponent } from './shared/components/cierre-inventario/cierre-inventario.component';
import { PeriodoInventarioComponent } from './shared/components/periodo-inventario/periodo-inventario.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MomentModule } from 'ngx-moment';
import { CatalogoReporteAntiguedadStock } from './shared/components/reporte-antiguedad-stock/catalogo/catalogo.component';
import { ExportAsModule } from 'ngx-export-as';
import { FormaMensaje } from './shared/components/control-pedidos/opcion-cerrar/forma.component';
import { CatalogoRangoFechaSincronizacionComponent } from './shared/components/configuracion/rango-fecha-sincronizacion/catalogo.component';
import { ComprobanteComponent } from './shared/components/Ventas/Comprobante/comprobante.component';
import { FormaDocPedidoComponent } from './shared/components/control-doc-pedido/forma/doc-pedido.component';
import { FiltroPipe } from './utilerias/pipes/filtro.pipe';
import { CatalogoProductosAlmacenExcelComponent } from './shared/components/alta-documentos-excel/catalogo-productos-almacen/catalogo.component';
import { FormaDocPedidoSalidaComponent } from './shared/components/control-doc-pedido-salida/forma/doc-pedido.component';
import { CatalogoControlPedidosSalidasComponent } from './shared/components/control-doc-pedido-salida/catalogo/catalogo.component';
import { ComprobantePedidoSalidaComponent } from './shared/components/comprobante-pedido-salida/catalogo.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormaDocumentoExcelAlmacenProductoComponent } from './shared/components/asignacion-usuario-producto/documentos-excel/forma.component';
import { CatalogoLogSincronizacion } from './shared/components/reporte-sincronizacion/catalogo/catalogo.component'
import { CatalogoControlCierresComponent } from './shared/components/control-cierres/catalogo/catalogo.component';
import { ComprobanteCierreComponent } from './shared/components/comprobante-cierre/catalogo.component';
import { CatalogoAsignacionProductoCritico } from './shared/components/asignacion-productos-criticos/catalogo/catalogo.component';
import { FormaDocumentoExcelProdouctoCriticoComponent } from './shared/components/asignacion-productos-criticos/documentos-excel/forma.component';
import { CatalogoControlPeriodosComponent } from './shared/components/control-periodos/catalogo/catalogo.component';
import { CatalogoCalcularConteo } from './shared/components/control-calcular-conteo/catalogo/catalogo.component';
import { ComprobanteConteoComponent } from './shared/components/comprobante-conteo/catalogo.component';
import { ComprobantePeriodoDiaInhabilComponent } from './shared/components/comprobante-periodo-dia-inhabil/catalogo.component';
import { FormaDocumentoExcelDiasInhabilesComponent } from './shared/components/control-periodos/documentos-excel-dinhabiles/forma.component';
import { CatalogoControlTraspasosComponent } from './shared/components/control-traspasos/catalogo/catalogo.component';
import { EncabezadoTraspasoComponent } from './shared/components/control-traspasos/encabezado/encabezado.component';
import { FormaDocTraspasoComponent } from './shared/components/control-doc-traspaso/doc-traspaso.component';
import { DetalleTraspasoComponent } from './shared/components/control-traspasos/producto/producto.component';
import { ListaDetallesTraspasoComponent } from './shared/components/control-traspasos/lista-productos/lista-productos.component';
import { FormaRegistroTraspasosComponent } from './shared/components/control-traspasos/forma/catalogo.component';
import { ComprobanteTraspasoComponent } from './shared/components/comprobante-traspaso/catalogo.component';
import { CatalogoReporteExistencias } from './shared/components/reporte-existencias/catalogo.component';
import { FormaDocumentoExcelPeriodoSemanalComponent } from './shared/components/control-periodos/documentos-excel-periodo-semanal/forma.component';
import { ComprobantePeriodoSemanalComponent } from './shared/components/comprobante-periodo-semanal/catalogo.component';
import { ComprobanteCalcularConteoComponent } from './shared/components/comprobante-calcular-conteo/catalogo.component';
import { CatalogoFechaOperacion } from './shared/components/Fecha-de-operacion/catalogo/catalogo.component';
import { FormaFechaConteoMensual } from './shared/components/Fecha-de-operacion/fecha-conteo-mensual/forma/forma.component';
import { ComprobanteConteoSemanalComponent } from './shared/components/comprobante-conteo-semanal/catalogo.component';
import { CatalogoFechaConteoMensual } from './shared/components/Fecha-de-operacion/fecha-conteo-mensual/Catalogo/catalogo.component';
import { CatalogoControlPeriodosSemanalComponent } from './shared/components/Fecha-de-operacion/periodos-semanales/catalogo/catalogo.component';
import { CatalogoControlTurnosConteosComponent } from './shared/components/Fecha-de-operacion/turnos-conteos/Catalogo/catalogo.component';
import { CatalogoMaquinariasComponent } from './shared/components/Maquinaria/catalogo/catalogo.component';
import { FormaMaquinariaComponent } from './shared/components/Maquinaria/forma/forma.component';
import { FormaPeriodosSemanales } from './shared/components/Fecha-de-operacion/periodos-semanales/forma/forma.component';
import { FormaTurnosConteos } from './shared/components/Fecha-de-operacion/turnos-conteos/forma/forma.component';
import { CatalogoControlDiasInhabilesComponent } from './shared/components/Fecha-de-operacion/dias-inhabiles/catalogo/catalogo.component';
import { FormaDiaInhabil } from './shared/components/Fecha-de-operacion/dias-inhabiles/forma/forma.component';
import { CatalogoControlPlataformasComponent } from './shared/components/Fecha-de-operacion/plataformas-conteo/catalogo/catalogo.component';
import { CatalogoControlConteosPrductosComponent } from './shared/components/control-conteos-productos/catalogo.component';
import { ComprobanteConteosProductosComponent } from './shared/components/comprobante-conteos-productos/catalogo.component';
import { ComprobanteDetalleSincronizacionComponent } from './shared/components/comprobante-detalle-sincronizacion/catalogo.component';
import { CatalogoControlEntradasComponent } from './shared/components/control-entradas/catalogo/catalogo.component';
import { FormaDocEntradaComponent } from './shared/components/control-entradas/forma/doc-entrada.component';
import { ComprobanteEntradaComponent } from './shared/components/control-entradas/comprobante-entrada/catalogo.component';
import { ImprimirComprobanteEntradaComponent } from './shared/components/control-entradas/imprimir-comprobante-entrada/catalogo.component';
import { FormaDocumentoExcelEntradasComponent } from './shared/components/control-entradas/documentos-excel/forma.component';
import { ListaPedidosComponent } from './shared/components/control-entradas/lista-pedidos/lista-pedidos.component';
import { CatalogoControlSalidasComponent } from './shared/components/control-salidas/catalogo/catalogo.component';
import { FormaDocSalidaComponent } from './shared/components/control-salidas/forma/doc-salida.component';
import { ComprobanteSalidaComponent } from './shared/components/control-salidas/comprobante-salida/catalogo.component';
import { ImprimirComprobanteSalidaComponent } from './shared/components/control-salidas/imprimir-comprobante-salida/catalogo.component';
import { ListaPedidosSalidasComponent } from './shared/components/control-salidas/lista-pedidos/lista-pedidos.component';
import { ComprobanteSalidaCatalogoComponent } from './shared/components/control-salidas/catalogo/comprobante-salida-catalogo/catalogo.component';

export function HttpLoaderFactory(http) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    ComprobanteConteoSemanalComponent,
    CatalogoFechaOperacion,
    FormaFechaConteoMensual,
    CatalogoFechaConteoMensual,
    ComprobanteCalcularConteoComponent,
    ComprobantePeriodoSemanalComponent,
    FormaDocumentoExcelPeriodoSemanalComponent,
    CatalogoReporteExistencias,
    ComprobanteTraspasoComponent,
    FormaRegistroTraspasosComponent,
    ListaDetallesTraspasoComponent,
    DetalleTraspasoComponent,
    FormaDocTraspasoComponent,
    EncabezadoTraspasoComponent,
    CatalogoControlTraspasosComponent,
    FormaDocumentoExcelDiasInhabilesComponent,
    ComprobantePeriodoDiaInhabilComponent,
    ComprobanteConteoComponent,
    CatalogoCalcularConteo,
    CatalogoControlPeriodosComponent,
    CatalogoControlPeriodosSemanalComponent,
    CatalogoControlTurnosConteosComponent,
    FormaDocumentoExcelProdouctoCriticoComponent,
    CatalogoAsignacionProductoCritico,
    ComprobanteCierreComponent,
    ComprobanteConteosProductosComponent,
    ComprobanteDetalleSincronizacionComponent,
    CatalogoControlCierresComponent,
    CatalogoControlConteosPrductosComponent,
    AppComponent,
    NavComponent,
    PaginaLoginComponent,
    PaginaRegistroClienteComponent,
    LigaSeguraPipe,
    CatalogoUsuarioComponent,
    FormaUsuarioComponent,
    CatalogoTipoUsuarioComponent,
    FormaTipoUsuarioComponent,
    CatalogoRolComponent,
    FormaRolesUsuarioComponent,
    CatalogoDashBoardComponent,
    CatalogoPantallaComponent,
    FormaPantallaComponent,
    CatalogoTipoMenuComponent,
    FormaTipoMenuComponent,
    FormaAsignacionRolPantallaComponent,
    MaskTelefonoDirective,
    LoadComponent,
    FormContactoSoporteComponent,
    CatalogoContactoSoporteComponent,
    CatalogoNoAccesoComponent,
    CatalogoGrupoProductosComponent,
    FormaGrupoProductoComponent,
    CatalogoUnidadesMedida,
    FormaUnidadesMedida,
    CatalogoEmpresaMinera,
    FormaEmpresaMinera,
    CatalogoAlmacen,
    FormaAlmacen,
    CatalogoMinas,
    FormaMina,
    CatalogoProductos,
    FormaProductos,
    CatalogoEmpleados,
    FormaEmpleados,
    CatalogoMinaUbicaciones,
    FormaMinaUbicaciones,
    CatalogoAsignacionAlmacenProducto,
    FormaAsignacionAlmacenProducto,
    CatalogoAsignacionMinaEmpleado,
    FormaAsignacionMinaEmpleado,
    CatalogoArticulosAlternativos,
    FormaArticuloAlternativo,
    CatalogoReporteador,
    CatalogoTopProductos,
    CatalogoIndicadores,
    CatalogoInventario,
    FormaListaComponent,
    CatalogoProcesoEstatus,
    FormaProcesoEstatus,
    CatalogoControlAjustes,
    FormaAutorizarAjuste,
    FormaMensajeComponent,
    ListaAlmacen,
    FormaAsignacionUsuariosPantallasComponent,
    ComponentListaDePrecios,
    FormaListaDePrecios,
    CrearListaComponent,
    agregarListaComponent,
    preciosAlmacenesComponent,
    FormaAltaDocumentosExcelComponent,
    CatalogoEntradaExcelComponent,
    CatalogoProductosAlmacenExcelComponent,
    AnalisisPfepComponent,
    RequisicionPfepComponent,
    VentasCatalogo,
    SeleccionesComponent,
    FormaVentaComponent,
    VentasCatalogo,
    FormaVentaComponent,
    SeleccionesComponent,
    DetallesComponent,
    ComprobanteComponent,
    CatalogoConfiguracionPrincipalComponent,
    CatalogoParametrosPFEPComponent,
    ReservasPfepComponent,
    CatalogoControlPedidosComponent,
    ComprobantePedidoComponent,
    ComprobantePedidoSalidaComponent,
    FormaRegistroPedidosComponent,
    ListaDetallesPedidoComponent,
    DetallePedidoComponent,
    EncabezadoPedidoComponent,
    CatalogoEsquemaDesktop,
    FormaProductoComponent,
    DdVentasGenerales,
    FormaAsignacionEmpresaProductoComponent,
    CatalogoEmpresaProductoComponent,
    DdProductoConMenosMovimientos,
    DdControlRecepciones,
    DdEficiencia,
    DocumentosExternosRequiciciones,
    DocumentosExternosEntradas,
    InventorikpiComponent,
    ControlInventarioComponent,
    ParetoComponent,
    CatalogoAdministradorCorreos,
    FormaAdministradorCorreos,
    CatalogoTemporizadorNotificaciones,
    FormaTemporizadorNotificaciones,
    FormaProductoKardex,
    CierreInventarioComponent,
    PeriodoInventarioComponent,
    CatalogoReporteAntiguedadStock,
    CatalogoLogSincronizacion,
    FormaMensaje,
    CatalogoRangoFechaSincronizacionComponent,
    FormaDocPedidoComponent,
    FiltroPipe,
    CatalogoControlPedidosSalidasComponent,
    FormaDocPedidoSalidaComponent,
    FormaDocumentoExcelAlmacenProductoComponent,
    CatalogoMaquinariasComponent,
    FormaMaquinariaComponent,
    FormaPeriodosSemanales,
    FormaTurnosConteos,
    CatalogoControlDiasInhabilesComponent,
    FormaDiaInhabil,
    CatalogoControlPlataformasComponent,
    CatalogoControlEntradasComponent,
    FormaDocEntradaComponent,
    ComprobanteEntradaComponent,
    ImprimirComprobanteEntradaComponent,
    FormaDocumentoExcelEntradasComponent,
    ListaPedidosComponent,
    CatalogoControlSalidasComponent,
    FormaDocSalidaComponent,
    ComprobanteSalidaComponent,
    ImprimirComprobanteSalidaComponent,
    ListaPedidosSalidasComponent,
    ComprobanteSalidaCatalogoComponent


  ],
  exports: [LoadComponent],
  entryComponents: [
    ComprobanteConteoSemanalComponent,
    CatalogoFechaOperacion,
    FormaFechaConteoMensual,
    CatalogoFechaConteoMensual,
    ComprobanteCalcularConteoComponent,
    ComprobantePeriodoSemanalComponent,
    FormaDocumentoExcelPeriodoSemanalComponent,
    CatalogoReporteExistencias,
    ComprobanteTraspasoComponent,
    FormaRegistroTraspasosComponent,
    ListaDetallesTraspasoComponent,
    DetalleTraspasoComponent,
    FormaDocTraspasoComponent,
    EncabezadoTraspasoComponent,
    CatalogoControlTraspasosComponent,
    FormaDocumentoExcelDiasInhabilesComponent,
    ComprobantePeriodoDiaInhabilComponent,
    ComprobanteConteoComponent,
    CatalogoCalcularConteo,
    CatalogoControlPeriodosComponent,
    CatalogoControlPeriodosSemanalComponent,
    CatalogoControlTurnosConteosComponent,
    ComprobanteCierreComponent,
    ComprobanteConteosProductosComponent,
    ComprobanteDetalleSincronizacionComponent,
    CatalogoControlCierresComponent,
    CatalogoControlConteosPrductosComponent,
    CatalogoDashBoardComponent,
    FormaUsuarioComponent,
    FormaTipoUsuarioComponent,
    FormaRolesUsuarioComponent,
    FormaTipoMenuComponent,
    FormaPantallaComponent,
    FormaAsignacionRolPantallaComponent,
    FormContactoSoporteComponent,
    CatalogoContactoSoporteComponent,
    CatalogoNoAccesoComponent,
    FormaMina,
    FormaMinaUbicaciones,
    FormaEmpresaMinera,
    FormaAlmacen,
    FormaUnidadesMedida,
    FormaProductos,
    FormaAsignacionAlmacenProducto,
    FormaEmpleados,
    FormaAsignacionMinaEmpleado,
    FormaArticuloAlternativo,
    FormaProcesoEstatus,
    FormaAutorizarAjuste,
    FormaMensajeComponent,
    FormaAsignacionUsuariosPantallasComponent,
    FormaListaDePrecios,
    agregarListaComponent,
    preciosAlmacenesComponent,
    FormaAltaDocumentosExcelComponent,
    CatalogoEntradaExcelComponent,
    CatalogoProductosAlmacenExcelComponent,
    SeleccionesComponent,
    FormaVentaComponent,
    VentasCatalogo,
    SeleccionesComponent,
    DetallesComponent,
    ComprobanteComponent,
    CatalogoConfiguracionPrincipalComponent,
    CatalogoParametrosPFEPComponent,
    ComprobantePedidoComponent,
    ComprobantePedidoSalidaComponent,
    CatalogoEsquemaDesktop,
    DdVentasGenerales,
    FormaAsignacionEmpresaProductoComponent,
    DdProductoConMenosMovimientos,
    DdControlRecepciones,
    DdEficiencia,
    DocumentosExternosRequiciciones,
    DocumentosExternosEntradas,
    CatalogoAdministradorCorreos,
    FormaAdministradorCorreos,
    FormaTemporizadorNotificaciones,
    FormaProductoKardex,
    InventorikpiComponent,
    ParetoComponent,
    CatalogoReporteAntiguedadStock,
    CatalogoLogSincronizacion,
    FormaMensaje,
    CatalogoRangoFechaSincronizacionComponent,
    FormaDocumentoExcelAlmacenProductoComponent,
    CatalogoAsignacionProductoCritico,
    FormaDocumentoExcelProdouctoCriticoComponent,
    CatalogoMaquinariasComponent,
    FormaMaquinariaComponent,
    FormaPeriodosSemanales,
    FormaTurnosConteos,
    CatalogoControlDiasInhabilesComponent,
    FormaDiaInhabil,
    CatalogoControlPlataformasComponent,
    ImprimirComprobanteEntradaComponent,
    FormaDocumentoExcelEntradasComponent,
    ListaPedidosComponent,
    ComprobanteSalidaComponent,
    ImprimirComprobanteSalidaComponent,
    ListaPedidosSalidasComponent,
    ComprobanteSalidaCatalogoComponent


  ],
  imports: [
    NgxSkeletonLoaderModule,
    NgxsStoreModule,
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressBarModule,
    ScrollingModule,
    FormsModule,
    CusPageModule,
    CusCardModule,
    routing,
    NgxSpinnerModule,
    MatBadgeModule,
    ChartsModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatMomentDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MomentModule,
    ExportAsModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    LoadingService,
    CurrencyPipe,
    DatePipe,
    LigaSeguraPipe,
    WINDOW_PROVIDERS,
    HostService,
    DecimalPipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        closeOnNavigation: true,
        disableClose: true,
        autoFocus: true,
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500, verticalPosition: 'top' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: 'es-MX' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'L',
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccesoTokenInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
