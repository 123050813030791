import { DetalleDesktop } from './detalle-desktop';

export class DetalleEntrada extends DetalleDesktop {
  entradaId: number;
  cantidadRequisicion?: number;
  inventarioCantidad: number;
  existencia: number;
  comentario: string;
  linea: number;
  bloqueado: boolean;
  esRequisicion: boolean;
  remarcar:boolean;
  productoCodigoDescripcion: string;
  folioRequi: string;
}
