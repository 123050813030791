import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Entrada } from 'src/app/shared/models/entrada';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { startWith, map, ignoreElements } from 'rxjs/operators';
import { LoadingService } from 'src/app/loading/loading.service';
import { DatePipe } from '@angular/common';
import { NavComponent } from 'src/app/core/nav/nav.component';
import { DetalleConteo } from 'src/app/shared/models/detalle-conteo';
import { Producto } from 'src/app/shared/models/producto';
import { Contexto } from 'src/app/shared/api/contexto.service';
import moment from 'moment';
import { ConteoSemanal } from 'src/app/shared/models/conteo-semanal';
import { Periodos } from 'src/app/shared/models/periodos';
import { PeriodosSemanales } from 'src/app/shared/models/periodosSemanales';


@AutoUnsubscribe()
@Component({
  selector: 'catalogo-registro-conteo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class FormaRegistroConteoSemanalComponent implements OnInit, OnDestroy {
  id: number = 0;
  subSesion: Subscription;
  forma: FormGroup;
  entrada: Entrada = new Entrada();
  detalleConteo: DetalleConteo[] = [];
  conteo: ConteoSemanal = new ConteoSemanal();
  almacenId: number = 0;
  fechaInicio: Date;
  fechaFinal: Date;
  pipe = new DatePipe('en-US');
  hoy = this.pipe.transform(
    new Date(Date.now()),
    'dd'
  );
  mes = this.pipe.transform(
    new Date(Date.now()),
    'M'
  );

  fInicial= new Date(Date.now()).toLocaleString("en-US");
  fFinal: string | Date

  periodo: PeriodosSemanales


  detalleConteoSeleccionado: DetalleConteo[] = [];
  detalleConteoCapturado: DetalleConteo[] = [];

  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  /// sesionCred: Sesion = new Sesion();

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  productos: Producto[] = [];
  productosFiltrados: Observable<Producto[]>;
  productosSinFiltrar: Producto[];
  filtroProductos = new FormControl();
  listaProductos: Producto[] = [];

  fuenteDatos: MatTableDataSource<DetalleConteo> = new MatTableDataSource(
    []
  );
  columnasMostradas = [
    'productoCodigo',
    'productoDescripcion',
    'material',
    'unidadMedidaCodigo',
    'otro',
    'cantidadConteo',
    'cantidadExistencia',
    'cantidadDiferencia',
  ];

  constructor(
    private activateRouter: ActivatedRoute,
    public ventana: MatDialog,
    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta,
    private autenticacion: AutenticacionService,
    private router: Router,
    private spinner: LoadingService,
    private nav: NavComponent,
    public ctx: Contexto,


  ) {
    this.activateRouter.params.subscribe((params) => {
      this.id = +params['id'];
    });

  }

  async ngOnInit() {

    this.forma = this.formBuilder.group({
      fechaInicio: [new Date, Validators.required],
      fechaFinal: [new Date, Validators.required],
      productoId: [null, Validators.nullValidator],
      productoDescripcion: ['', Validators.required],
      referencia: ['', Validators.nullValidator],
    });

    if (this.id == 0) {
      debugger

      
      await this.ctx.PeriodosSemanas.ObtenerPeriodosSemanasAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((periodos) => {
        if (periodos) {
          debugger
          this.fechaInicio = moment(periodos['fechaInicio']).toDate()
          this.fechaFinal = moment(periodos['fechaFinal']).toDate()

          this.forma.get('fechaFinal').setValue(this.fechaFinal)
          this.forma.get('fechaInicio').setValue(this.fechaInicio)
        }
        else {
          this.alerta.mostrarAdvertencia("No existe conteo semanal asignado para el dia de hoy")
          return;
        }

      })

      console.log(this.fechaFinal);
      if (this.fechaInicio > this.fechaFinal) {
        this.alerta.mostrarAdvertencia(
          'La fecha final es menor a la fecha inicial'
        );
        return;
      } else {

        this.spinner.show(
          'Espera un momento... \n Estamos calculando los valores de existencias de los productos desde la fecha ' +
          moment(this.fechaInicio).toString() +
          ' hasta la fecha ' +
          moment(this.fechaFinal) .toString()
           + ', del almacén ' +
           this.autenticacion.credencial.almacenDescripcion
        );

        await this.cargarDetalle();
        for (const producto of this.fuenteDatos.data) {
          await this.cantidadConteo(producto.productoId, producto.cantidadConteo);
        }
        this.spinner.hide();
        await this.cargarProductos();
      }




    } else {
      //let conteo = await this.db.conteos.ConteoId(this.id);
      //this.conteo = conteo[0]
      //Object.assign(this.forma.value, this.conteo);
      this.forma.reset(this.forma.value);

      this.fechaInicio = this.forma.get('fechaInicio').value;
      this.fechaFinal = this.forma.get('fechaFinal').value;

      //this.fuenteDatos = new MatTableDataSource(this.conteo.detalles);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    }

  }

  async cargarProductos() {
    if (this.listaProductos && this.listaProductos.length > 0) {
      this.productosSinFiltrar = this.listaProductos;
      this.productosFiltrados = this.filtroProductos.valueChanges.pipe(
        startWith<string | Producto>(''),
        map((t) => (typeof t === 'string' ? t : t == null ? '' : t.codigo)),
        map((t) => this.filtrarProductos(t))
      );
    }
  }

  private filtrarProductos(nombre: string): Producto[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.productosSinFiltrar.filter(
      (t) => t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  productoSeleccionado(producto: Producto) {
    console.log(producto.productoId);
    this.forma
      .get('productoDescripcion')
      .setValue(producto.productoDescripcion);
    this.forma.get('productoId').setValue(producto.productoId);
    this.detalleConteoSeleccionado.length = 0;
    // this.detalleConteoSeleccionado = this.detalleConteo.filter(
    //   (e) => e.productoId == producto.productoId
    // );
    this.fuenteDatos = new MatTableDataSource(this.detalleConteoSeleccionado);
  }



  async guardar(esBorrador: boolean) {
    if (this.id == 0) {

      await this.ctx.ConteosProductos.ObtenerUltimoConteo(this.autenticacion.credencial.almacenId)
        .toPromise()
        .then(async (conteo) => {
          if (conteo) {
            this.alerta.mostrarAdvertencia("Ya existe un conteo con la fecha seleccionada, verifique nuevamente")
            return
          }

          this.spinner.show('Espera un momento...');

          if (esBorrador) {
            this.conteo.tipoConteoId = 2;
            this.conteo.esBorrador = true;
            this.conteo.fechaDocumento = new Date(Date.now());
            this.conteo.detalles = this.fuenteDatos.data;
            this.conteo.fechaSincronizacion = null;
            this.conteo.estaSincronizado = false;
            this.conteo.fechaInicio = new Date(this.fechaInicio);
            this.conteo.fechaFinal = new Date(this.fechaFinal);
            this.conteo.fechaInicioConteo = this.fInicial;
            this.conteo.fechaFinalConteo = new Date(Date.now()).toLocaleString("en-US");
            this.conteo.cantidadDiferencias = this.conteo.detalles.filter(
              (c) => c.existencia != c.cantidad
            ).length;
            this.conteo.almacenId = this.autenticacion.credencial.almacenId;
            this.conteo.usuarioId = this.autenticacion.credencial.usuarioId;


            await this.ctx.ConteosProductos.GuardarConteoSemanal(this.conteo)
              .toPromise()
              .then(conteo => {
                debugger
                if (conteo.ok) {
                  this.spinner.hide();
                  this.alerta.mostrarExito(
                    'Registro de conteo físico semanal guardado con éxito'
                  );
                }
                else {
                  this.alerta.mostrarError(
                    'Error al registar conteo físico semanal'
                  );
                  this.spinner.hide();
                }
              });

          } else {
            this.conteo.tipoConteoId = 2;
            this.conteo.esBorrador = false;
            this.conteo.fechaDocumento = new Date(Date.now());
            this.conteo.detalles = this.fuenteDatos.data;
            this.conteo.fechaSincronizacion = null;
            this.conteo.estaSincronizado = false;
            this.conteo.fechaInicio = new Date(this.fechaInicio);
            this.conteo.fechaFinal = new Date(this.fechaFinal);
            this.conteo.fechaInicioConteo = this.fInicial;
            this.conteo.fechaFinalConteo = new Date(Date.now()).toLocaleString("en-US");
            this.conteo.cantidadDiferencias = this.conteo.detalles.filter(
              (c) => c.existencia != c.cantidad
            ).length;
            this.conteo.almacenId = this.autenticacion.credencial.almacenId;
            this.conteo.usuarioId = this.autenticacion.credencial.usuarioId;

            await this.ctx.ConteosProductos.GuardarConteoSemanal(this.conteo)
              .toPromise()
              .then(conteo => {
                debugger
                if (conteo.ok) {
                  this.spinner.hide();
                  this.alerta.mostrarExito(
                    'Registro de conteo físico semanal guardado con éxito'
                  );

                  this.router.navigate(['/ConteoSemanal/']);
                }
                else {
                  this.alerta.mostrarError(
                    'Error al registar conteo físico semanal'
                  );
                  this.spinner.hide();
                }
              });

          }

        });

    } else {
      this.spinner.show('Espera un momento...');

      if (esBorrador) {
        // this.conteo.tipoConteoId = 2;
        // this.conteo.esBorrador = true;
        // this.conteo.fechaDocumento = new Date(Date.now());
        // this.conteo.detalles = this.fuenteDatos.data;
        // this.conteo.fechaSincronizacion = null;
        // this.conteo.estaSincronizado = false;
        // this.conteo.fechaInicio = new Date(this.fechaInicio);
        // this.conteo.fechaFinal = new Date(this.fechaFinal);
        // this.conteo.fechaInicioConteo = this.fInicial
        // this.conteo.fechaFinalConteo = new Date(Date.now());

        // let conteo = await this.db.conteos.Actualizar(
        //   this.id,
        //   this.conteo
        // );
        // if (conteo && conteo.id > 0) {
        //   this.spinner.hide();
        //   this.alerta.mostrarExito(
        //     'Registro de conteo físico semanal guardado con éxito'
        //   );
        //   this.router.navigate(['ConteoSemanal']);
        // } else {
        //   this.alerta.mostrarError(
        //     'Registro de conteo físico semanal guardado con éxito'
        //   );
        //   this.spinner.hide();
        // }
      } else {
        // this.conteo.esBorrador = false;
        // //CAMBIO A NO SINCRONIZADO PARA QUE CUANDO SE VUELVA A SINCRONIZAR CONTEOS, ACTUALIZE EL DATO DE NO BORRADOR
        // this.conteo.estaSincronizado = false;
        // this.conteo.fechaDocumento = new Date(Date.now());
        // this.conteo.fechaInicio = new Date(this.fechaInicio);
        // this.conteo.fechaFinal = new Date(this.fechaFinal);
        // this.conteo.fechaInicioConteo = this.fInicial;
        // this.conteo.fechaFinalConteo = new Date(Date.now());
        // this.conteo.detalles = this.fuenteDatos.data;
        // let conteo = await this.db.conteos.Actualizar(
        //   this.id,
        //   this.conteo
        // );
        // if (conteo && conteo.id > 0) {
        //   this.spinner.hide();
        //   this.alerta.mostrarExito(
        //     'Registro de conteo físico semanal guardado con éxito'
        //   );
        //   this.router.navigate(['ConteoSemanal']);
        // } else {
        //   this.spinner.hide();
        //   this.alerta.mostrarError(
        //     'Registro de conteo físico semanal guardado con éxito'
        //   );
        // }
      }
    }
  }

  async limpiar() {
    //this.forma.get('fechaInicio').setValue(null);
    //this.forma.get('fechaFinal').setValue(null);
    this.forma.get('productoId').setValue(null);
    this.forma.get('productoDescripcion').setValue('');
    this.detalleConteo.length = 0;
    this.detalleConteoSeleccionado.length = 0;
    await this.cargarProductos();
    await this.cargarDetalle();
  }

  async limpiarProductos() {
    this.forma.get('productoId').setValue(null);
    this.forma.get('productoDescripcion').setValue('');
    await this.cargarProductos();
    this.fuenteDatos.data = this.detalleConteo;
    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
  }

  async cargarDetalle() {
    this.spinner.show(
      'Cargando productos del almacén ' +
      this.autenticacion.credencial.almacenDescripcion
    );

debugger
    var fInicio = moment(this.forma.get('fechaInicio').value).format('DD-MM-YYYY')
    var fFin = moment(this.forma.get('fechaFinal').value).format('DD-MM-YYYY')



    await this.ctx.ConteosProductos.ObtenerMovimientosArticulos(this.autenticacion.credencial.almacenId, fInicio, fFin)
      .toPromise()
      .then((periodos) => {

        if (periodos.length > 0) {
          this.detalleConteo = periodos.map((e) => {
            return {
              productoId: e.productoId,
              productoCodigo: e.codigoProducto,
              productoDescripcion: e.productoDescripcion,
              material: e.material,
              unidadMedidaCodigo: e.unidadMedidaCodigo,
              otro: e.otro,
              cantidadConteo: 0,
              existencia: e.cantidad != null ? e.cantidad : 0,
              diferencia: e.cantidad != null ? e.cantidad : 0,
              cantidad: e.cantidad,
              abreviaturaMedida: "",
              orden: "",
              productoCodigoDescripcion: "",
              comentarios: "",
              codigoProductoReemplazo: "",
              linea: 0,
              conteoId: 0,
              fechaSolicitud: "",
              id: 0

            } as DetalleConteo;
          });
          this.fuenteDatos = new MatTableDataSource(this.detalleConteo);
          this.fuenteDatos.paginator = this.paginador;
          this.fuenteDatos.sort = this.ordenador;
        }
      });

    this.spinner.hide();
  }

  async cantidadConteo(productoId: number, cantidad: any) {
    try {
      const cantidadInven = await this.ctx.ConteosProductos.ObtenerInventario(this.autenticacion.credencial.almacenId, productoId)
        .toPromise()
        .then((articulo) => {

          let cantidadInventario: number = 0.0;

          cantidadInventario = articulo[0].cantidad

          cantidadInventario = cantidadInventario != null ? cantidadInventario : 0;
          const linea = this.fuenteDatos.data.find(
            (e) => e.productoId == productoId
          );
          linea.cantidadConteo = parseFloat(cantidad);
          linea.cantidad = parseFloat(cantidad);
          linea.existencia = cantidadInventario;
          linea.diferencia = cantidadInventario - parseFloat(cantidad);
        })

    } catch (e) {
      this.alerta.mostrarAdvertencia('¡Favor de ingresar solo números! ');
    }
  }


  ngOnDestroy(): void {
    if (this.subSesion != undefined) {
      this.subSesion.unsubscribe();
    }
  }
}
