import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Salida } from '../../../models/Salida';
import { Validators, FormControl } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-salida',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteSalidaComponent implements OnInit {
  columnas = [
    'cantidad',
    'productoCodigo',
    'material',
    'producto',
    'unidad',
    'otro',
    'orden',
  ];
  controlFirma = new FormControl(null, Validators.required);
  firma = '';
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
      },
      margins: {
        top: '20',
        left: '20',
        right: '20',
      },
    },
  };
  constructor(
    public dialogRef: MatDialogRef<ComprobanteSalidaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public args: { salida: Salida; soloLectura: boolean },
    private alerta: ServicioAlerta,
    private exportAsService: ExportAsService
  ) { }

  ngOnInit() {
    if (this.args.soloLectura) {
      this.controlFirma.disable();
    } else {
      this.controlFirma.enable();
    }
    console.log(this.args.salida);
  }

  aceptar() {
    this.dialogRef.close({ acepto: true });
  }

  regresar() {
    this.dialogRef.close({ acepto: false, firma: '' });
  }

  limpiar() {
    this.controlFirma.reset();
  }

  descargarDocumento(): void {
    this.exportAsService
      .save(this.exportPDF, 'Doc. salida ' + this.args.salida.folio)
      .subscribe();
  }
}
