import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef, MatSort, MatTableDataSource } from '@angular/material';
import { Salida } from '../../../models/Salida';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-entrada',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ImprimirComprobanteSalidaComponent implements OnInit {
  columnas = ['Codigo',
    'ProductoDescripcion',
    'Material',
    'Cantidad',
    'Ubicacion',
    'Orden',
  ];
  salida: Salida = new Salida();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  empleadoRecibe: string = "";
  nombreEmpleado: string = "";
  fechaLocal: Date = new Date();
  folio: string = "";

  fechaDocumento: string = "";
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ImprimirComprobanteSalidaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public sal: Salida,
    private exportAsService: ExportAsService,
    public autenticacion: AutenticacionService,
  ) {

    this.salida = sal;
    this.folio = "- Folío: " + " - " + this.salida.folio.toString() + " - ";
    this.fechaDocumento = "- Fecha de documento: " + " - " + new Date(this.salida.fechaDocumento).toISOString().slice(0, 10) + " - ";


    this.cantidadTotal = this.salida.detalles.reduce((suma, detalle) => suma + detalle.cantidad, 0);
    this.cantidadLineas = this.salida.detalles.length;
    this.empleadoRecibe = this.salida.empleadoRecibe;

  }

  ngOnInit() { }


  regresar() {
    this.dialogRef.close();
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Salida ' + this.salida.folio + '.pdf').subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Salida ' + this.salida.folio + '.xlsx').subscribe();
  }


  imprimirPDF() {

  }
}
