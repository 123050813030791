import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { PeriodosSemanales } from '../models/periodosSemanales';
import { TurnoConteo } from '../models/turno-conteo';

export class RepositorioPeriodosSemanas extends Repositorio<PeriodosSemanales> {
  constructor(http: HttpClient) {
    super(http, 'PeriodosSemanas');
  }

  ObtenerPeriodosSemanasAlmacen(almacenId: number): Observable<PeriodosSemanales[]> {
    const ruta = `${this.ruta}/ObtenerPeriodosSemanasAlmacen/${almacenId}`;
    return this.http.get<PeriodosSemanales[]>(ruta);
  }

  ObtenerTurnoConteoAlmacen(almacenId: number): Observable<TurnoConteo[]> {
    const ruta = `${this.ruta}/ObtenerTurnosConteoSemanalAlmacen/${almacenId}`;
    return this.http.get<TurnoConteo[]>(ruta);
  }

}
