import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { Router } from '@angular/router';
import { Entrada } from 'src/app/shared/models/entrada';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Subscription } from 'rxjs';
import { ComprobanteEntradaComponent } from '../comprobante-entrada/catalogo.component';
import { ImprimirComprobanteEntradaComponent } from '../imprimir-comprobante-entrada/catalogo.component';
import { FormaDocumentoExcelEntradasComponent } from '../documentos-excel/forma.component';
@Component({
  selector: 'catalogo-control-inventario-entradas',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlEntradasComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  fuenteDatos: MatTableDataSource<Entrada> = new MatTableDataSource([]);
  sub: Subscription;

  file: File;
  arrayBuffer: any;
  columnasMostradas = [
    'almacenDescripcion',
    'folio',
    'referencia',
    'fechaDocumento',
    'nombreUsuario',
    'esBorrador',
    'opciones',
  ];
  constructor(
    public ventana: MatDialog,
    private db: Contexto,
    private dialog: MatDialog,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private alerta: ServicioAlerta,
    private spinner: LoadingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );
  }
  cargarTabla(): void {
    this.db.controlEntradas.ObtenerListaEntradas(this.autenticacion.credencial.almacenId).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar las entradas de inventario');
      })
  }


  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  abrirFormaNueva(id?: string): void {
    const dato = id ? id : 0;
    this.router.navigate(['/RegistroEntradas/', dato]);
  }

  verComprobante(entrada: Entrada): void {
    this.db.controlEntradas.ObtenerEntradaId(entrada.id).toPromise().then((e) => {
      this.dialog.open(ComprobanteEntradaComponent, {
        data: e,
        panelClass: 'comprobante',
      });
    });

  }

  imprimirComprobanteDetalle(entrada: Entrada): void {
    this.db.controlEntradas.ObtenerEntradaId(entrada.id).toPromise().then((e) => {
      this.dialog.open(ImprimirComprobanteEntradaComponent, {
        data: e,
        panelClass: 'comprobante',
      });
    });
  }

  async cargarExcel() {
    let forma = this.ventana.open(FormaDocumentoExcelEntradasComponent, { data: {}, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => {
      this.cargarTabla();
    });

  }
  descargarGuiaLayout() {
    let archivo = window.open("assets/guias-excel/Layout_Entradas_Desktop.xlsx", '_blank');
    setTimeout(function () {
      archivo.close();
    }, 100);
    return false;
  }
  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}
