import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, ThemePalette } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import * as XLSX from 'xlsx';
import { ExcelEntradas } from 'src/app/shared/models/excel-entradas';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { Productos } from 'src/app/shared/models/Productos';
@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaDocumentoExcelEntradasComponent implements OnInit {

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }
  columnas = ['codigo', 'productoDescripcion', 'cantidad'];
  file: File;
  arrayBuffer: any;
  docs: string[] = [];
  entradas: ExcelEntradas[] = [];
  guardado: EventEmitter<void> = new EventEmitter<void>();
  productos: Productos[] = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public dato: any,

    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private db: Contexto,
    private ventana: MatDialogRef<FormaDocumentoExcelEntradasComponent>,
    private exportAsService: ExportAsService,
  ) {

  }

  ngOnInit() { }

  archivos($event) {
    this.entradas.length = 0;
    this.file = $event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.entradas = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as ExcelEntradas[];
      console.table(this.entradas);
      this.docs.push(this.file.name);
    }
    fileReader.readAsArrayBuffer(this.file);

  }


  async guardarEntradas() {
    if (this.entradas && this.entradas.length > 0) {
      //let sesion = await this.db.sesion.ObtenerSesion();
      await this.db.controlEntradas.EntrasExcel(this.entradas).toPromise().then(entradas => {
        if (entradas) {
          this.alerta.mostrarExito("Entradas guardadas con éxito");
          if (entradas.productos.length > 0) {
            this.productos = entradas.productos;
          }
        } else {
          this.alerta.mostrarError("Error al guardar las entradas");

        }
      }).catch(error => {
        console.log(error);
        this.alerta.mostrarExito("Error interno del sistema");
      });
    } else {
      this.alerta.mostrarAdvertencia("Ingrese el archivo excel para dar de alta los documentos de entrada");
    }
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Productos_no_encontrados').subscribe();
  }

  cerrar(): void {
    this.guardado.emit();
    this.cerrar();
  }
}
