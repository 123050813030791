import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { UnidadesMedida } from 'src/app/shared/models/UnidadesMedida';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleAjustes } from 'src/app/shared/models/detalle-ajustes';
import { FormaMensajeComponent } from 'src/app/utilerias/mensaje-en-modal/forma/forma.component';
import { Ajustes } from 'src/app/shared/models/Ajustes';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})

export class FormaAutorizarAjuste implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: Ajustes;
  id: number;
  inactivar: boolean;

  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<DetalleAjustes> = new MatTableDataSource([]);
  columnasMostradas = ['codigoDescripcionProducto', 'cantidad'];


  constructor(private formBuilder: FormBuilder, private ctx: Contexto, private alerta: ServicioAlerta, private activateRouter: ActivatedRoute, private router: Router, public ventana: MatDialog) {
    this.activateRouter.params.subscribe(params => {
      this.id = +params['id'];
    });




  }

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  async ngOnInit() {


    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        minaDescripcion: ['', Validators.nullValidator],
        almacenDescripcion: ['', Validators.nullValidator],
        ubicacionDescripcion: ['', Validators.nullValidator],
        fechaRegistro: ['', Validators.nullValidator],
        usuarioNombreCompleto: ['', Validators.nullValidator],
        tipoEstatus: ['', Validators.nullValidator],
        comentarios: ['', Validators.nullValidator],
        // SECCION AUTORIZACION
        fechaAutorizacion: [new Date(), Validators.required],
        quienAutoriza: ['', Validators.required],
        comentariosAutorizacion: ['', Validators.required],
      },
    );


    await this.llenarFormulario();
    this.tabla();
  }

  async llenarFormulario(): Promise<void> {
    this.modelo = await this.ctx.controlAjustes.obtenerAjuste(this.id).toPromise();
    this.f['id'].setValue(this.id);
    this.f['minaDescripcion'].setValue(this.modelo.minaDescripcion);
    this.f['almacenDescripcion'].setValue(this.modelo.almacenDescripcion);
    this.f['ubicacionDescripcion'].setValue(this.modelo.ubicacionDescripcion);
    this.f['fechaRegistro'].setValue(this.modelo.fechaRegistro);
    this.f['usuarioNombreCompleto'].setValue(this.modelo.usuarioNombreCompleto);
    this.f['tipoEstatus'].setValue(this.modelo.estatusDescripcion);
    this.f['comentarios'].setValue(this.modelo.comentarios);

    if(this.modelo.estatusId === 4 || this.modelo.estatusId === 6 || this.modelo.estatusDescripcion === "Borrador")
    {
      this.inactivar = false;
      this.f['fechaAutorizacion'].setValue(this.modelo.fechaAutorizacion);
      this.f['quienAutoriza'].setValue(this.modelo.quienAutoriza);
      this.f['comentariosAutorizacion'].setValue(this.modelo.comentariosAutorizacion);
    }else{
      this.inactivar = true;
    }

  }

  tabla(): void {

    this.ctx.controlAjustes.obtenerDetalleAjuste(this.id).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;

    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar el detalle del ajuste');
      })
  }


  async autorizar(): Promise<void> {

    if (this.forma.valid) {
      const model = this.forma.value as Ajustes;
      let respuesta = await this.mensaje("¿Desea autorizar el siguiente ajuste?", false, false, false, false, true, false, true, false);
      if (respuesta === 4) {
        model.estatusId = 4;
        this.ctx.controlAjustes.guardar(model).toPromise().then(() => {
          this.alerta.mostrarExito("Ajuste autorizado");
          this.router.navigate(['/ControlAjustes']);
        }).catch(e => {
          console.log(e);
          this.alerta.mostrarError('¡Error interno al guardar! ');
        });
      } else {
        model.estatusId = 6;
        this.ctx.controlAjustes.guardar(model).toPromise().then(() => {
          this.alerta.mostrarExito("Ajuste rechazado");
        }).catch(e => {
          console.log(e);
          this.alerta.mostrarError('¡Error interno al guardar! ');
        });
      }
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }

  }


  irAjustes(): void {
    this.router.navigate(['/ControlAjustes']);
  }

  async mensaje(mensaje: string,
    mostrarCargando?: boolean,
    botonAbierto?: boolean,
    botonCerrado?: boolean,
    botonPendiente?: boolean,
    botonAutorizar?: boolean,
    botonCancelar?: boolean,
    botonRechazado?: boolean,
    botonOk?: boolean,

  ): Promise<number> {
    let respuesta = 0
    const dato = {
      mensaje,
      mostrarCargando,
      botonAbierto,
      botonCerrado,
      botonPendiente,
      botonAutorizar,
      botonCancelar,
      botonRechazado,
      botonOk
    };
    const forma = this.ventana.open(FormaMensajeComponent, {
      data: dato,
      panelClass: 'form-container',
    });
    respuesta = await forma.afterClosed().toPromise();
    return respuesta;
  }


}
