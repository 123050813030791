import { Injectable } from '@angular/core';
import { Credencial } from 'src/app/shared/models/credencial';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {

  readonly propiedadCredencial = 'credencial';
  readonly propiedadPerfil = 'perfil';
  rutaRedireccion: string;
  public get credencial(): Credencial {
    const credencialTexto = window.localStorage.getItem(this.propiedadCredencial);
    if (credencialTexto) {
      return JSON.parse(credencialTexto);
    }
    return undefined;
  }

  constructor(private router: Router) { }

  asignarCredencial(credencial: Credencial): void {
    let crede = window.localStorage.getItem(this.propiedadCredencial);
    if(crede != null)
    {
      window.localStorage.removeItem(this.propiedadCredencial);
    }
    window.localStorage.setItem(this.propiedadCredencial, JSON.stringify(credencial));
  }

  public get ingreso(): boolean {
    const credencial = window.localStorage.getItem(this.propiedadCredencial);
    if (!credencial) {

      return false;
    }

    return true;
  }

  salir(): void {
    window.localStorage.removeItem(this.propiedadCredencial);
    this.router.navigate(['Login']);
  }

  accesoRestringido(): void {
    this.router.navigate(['AccesoRestringido']);
  }

  private obtenerCredencial(): Credencial {
    let json = window.localStorage.getItem(this.propiedadCredencial);
    if (!json)
    {
      return {
        roles: null,
        token: "",
        expira: null,
        tokenActualizable: "",
        tipoUsuarioId: 0,
        tipoUsuarioDescripcion: "",
        nombreCompleto: "",
        usuarioId: 0,
        pantallasOperacion: null,
        pantallasConfiguracion: null,
        pantallasSeguridad: null,
        pantallasCatalogos: null,
        pantallasReportes: null,
        usuarioAlmacen: null,
        empresaId: 0,
        almacenId: 0,
        entradaId: 0,
        almacenDescripcion: "",
        lang: "",
        app: ''
      };
    }

    return JSON.parse(json);
  }

  public obtenerToken(): string {
    return this.obtenerCredencial().token;
  }
}
