import { HttpClient } from '@angular/common/http';
import { Repositorio } from "./repositorio";
import { Salida } from "../models/Salida";
import { Observable } from 'rxjs';
import { Empleados } from '../models/Empleados';
import { DetallePedido } from '../models/detalle-pedido';
import { Respuesta } from '../models/respuesta';
import { Inventario } from '../models/Inventario';
import { Pedido } from '../models/pedido';
import { PedidoSalida } from '../models/pedido-salida';

export class RepositorioControlSalidas extends Repositorio<Salida> {
    constructor(http: HttpClient) {
        super(http, 'ControlSalidas');
    }

    ObtenerListaSalidas(almacenId: number): Observable<Salida[]> {
        const ruta = `${this.ruta}/ObtenerListaSalidas/${almacenId}`;
        return this.http.get<Salida[]>(ruta);
    }

    ObtenerSalidaId(salidaId: number): Observable<Salida> {
        const ruta = `${this.ruta}/ObtenerSalidaId/${salidaId}`;
        return this.http.get<Salida>(ruta);
    }
    ObtenerFechaUltimaSalida(almacenId: number): Observable<Date> {
        const ruta = `${this.ruta}/ObtenerFechaUltimaSalida/${almacenId}`;
        return this.http.get<Date>(ruta);
    }
    ObtenerListaEmpleados(almacenId: number): Observable<Empleados[]> {
        const ruta = `${this.ruta}/ObtenerListaEmpleados/${almacenId}`;
        return this.http.get<Empleados[]>(ruta);
      }
    CrearSalidaNueva(almacenId: number, usuarioId: number, nombreCompleto: string): Observable<Salida> {
        const ruta = `${this.ruta}/CrearSalidaNueva/${almacenId},${usuarioId},${nombreCompleto}`;
        return this.http.get<Salida>(ruta);
    }
    ObtenerDetallePedidos(idsPedido: number): Observable<DetallePedido> {
        const ruta = `${this.ruta}/ObtenerDetallePedidos/${idsPedido}`;
        return this.http.get<DetallePedido>(ruta);
    }
    CargarPedidos(almacenId: number): Observable<PedidoSalida[]>{
        const ruta = `${this.ruta}/cargarPedidos/${almacenId}`;
        return this.http.get<PedidoSalida[]>(ruta);
    }
    ObtenerTipoMotivos(): Observable<any>{
        const ruta = `${this.ruta}/ObtenerTipoMotivos/`;
        return this.http.get<any>(ruta);
    }

    //post

    EnviarSalidaBD(salida: Salida): Observable<Respuesta<Salida>>{
        const ruta = `${this.ruta}/EnviarSalidaBD`;
        return this.http.post<Respuesta<Salida>>(ruta, salida);
    }
    ModificarSalidaBD(salida: Salida): Observable<Respuesta<Salida>>{
        const ruta = `${this.ruta}/ModificarSalidaBD`; //TOdo de momento
        return this.http.post<Respuesta<Salida>>(ruta, salida);
    }
    RestarAlInventario(salida: Salida): Observable<Respuesta<Inventario>>{
        const ruta = `${this.ruta}/RestarAlInventario`;
        return this.http.post<Respuesta<Inventario>>(ruta, salida);
    }
    ModificarPedido(salida: Salida, estado: number): Observable<Respuesta<Pedido>>{
        const ruta = `${this.ruta}/ModificarPedido`;
        salida.estado = estado;
        return this.http.post<Respuesta<Pedido>>(ruta, salida);
    }
}