import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { DatePipe } from '@angular/common';
import { ConteoSemanal } from 'src/app/shared/models/conteo-semanal';
import { PeriodosSemanales } from 'src/app/shared/models/periodosSemanales';
import { ComprobanteConteosProductosComponent } from '../../comprobante-conteos-productos/catalogo.component';
@Component({
  selector: 'catalogo-conteo-semanal',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlConteosSemanalComponent
  implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  subSesion: Subscription;
  arrayBuffer: any;
  periodosSemanas: PeriodosSemanales[]
  fuenteDatos: MatTableDataSource<ConteoSemanal> = new MatTableDataSource([]);
  fInicial: string | Date
  fFinal: string | Date

  columnasMostradas = [
    'almacenDescripcion',
    'folio',
    'nombreUsuario',
    'fechaDocumento',
    'inicioConteo',
    'finalConteo',
    'semana',
    'esBorrador',
    'cantidadDiferencias',
    'enviadoPorCorreo',
    'opciones',
  ];

  pipe = new DatePipe('en-US');
  hoy = this.pipe.transform(
    new Date(Date.now()),
    'dd/MM/yyyy'
  );

  constructor(
    public ventana: MatDialog,
    private alerta: ServicioAlerta,
    private router: Router,
    private dialog: MatDialog,
    private autenticacion: AutenticacionService,
    public ctx: Contexto,
    private spinner: LoadingService
  ) {

  }

  async ngOnInit() {
    this.cargarTabla();
  }

  cargarTabla() {

    this.ctx.ConteosProductos.ObtenerConteosSemanal(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((conteos) => {
        debugger
        this.fuenteDatos = new MatTableDataSource(conteos);
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.paginator = this.paginador;
      })
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  async abrirForma(id?: string) {
    const dato = id ? id : 0;
    this.router.navigate(['/Registro-Conteo-Semanal/', dato]);
  }

  async comprueboFechaParaSemanal() {
    this.ctx.PeriodosSemanas.ObtenerPeriodosSemanasAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((periodos) => {
        if (periodos) {
          this.ctx.PeriodosSemanas.ObtenerTurnoConteoAlmacen(this.autenticacion.credencial.almacenId)
            .toPromise()
            .then((turnos) => {
              if (turnos) {
                this.abrirForma();
              } else {
                this.alerta.mostrarAdvertencia("fuera horario")
              }
            })
        }
        else {
          this.alerta.mostrarAdvertencia("No existe conteo semanal asignado para el dia de hoy")
        }

      })
  }

  verComprobante(conteo: ConteoSemanal): void {
    this.ctx.ConteosProductos
      .ObtenerComprobanteConteo(conteo.id)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobanteConteosProductosComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px',
        });
      });
  }

  ngOnDestroy(): void {
  }

  async enviado(Conteo: ConteoSemanal): Promise<void> {
    try {
      this.spinner.show('Enviando conteo semanal por correo electrónico...');
      const respuesta = await this.ctx.ConteosProductos.enviarCoteoSemanal(Conteo)
        .toPromise()
        .then((notificacion) => {
          if (notificacion.ok) {
            this.spinner.hide();
            this.alerta.mostrarExito('Conteo semanal enviado y actualizado con éxito');
            this.cargarTabla();
          }
          else {
            this.spinner.hide();
            this.alerta.mostrarError('Error al enviar la notificación');
          }
          this.spinner.hide();
        })

    } catch (error) {
      console.log(error);
      this.alerta.mostrarError('Error interno del sistema, al enviar la notificación');
      this.spinner.hide();
    }
  }
}
