import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetalleEntrada } from '../models/detalle-entrada';
import { Entrada } from '../models/entrada';
import { ExcelEntradas } from '../models/excel-entradas';
import { RespuestaEntradasExcel } from '../models/respuesta-excel-entradas';
import { Pedido } from '../models/pedido';
import { DetallePedido } from '../models/detalle-pedido';
import { Productos } from '../models/Productos';
import { Inventario } from '../models/Inventario';
import { Respuesta } from '../models/respuesta';

export class RepositorioControlEntradas extends Repositorio<Entrada> {
    constructor(http: HttpClient) {
        super(http, 'ControlEntradas');
    }

    //#region Peticiones GET ***
    
    ObtenerListaEntradas(almacenId: number): Observable<Entrada[]> {
        const ruta = `${this.ruta}/ObtenerListaEntradas/${almacenId}`;
        return this.http.get<Entrada[]>(ruta);
    }

    ObtenerEntradaId(entradaId: number): Observable<Entrada[]> {
        const ruta = `${this.ruta}/ObtenerEntradaId/${entradaId}`;
        return this.http.get<Entrada[]>(ruta);
    }
    ObtenerDetalleEntradaId(entradaId: number): Observable<DetalleEntrada[]> {
        const ruta = `${this.ruta}/ObtenerDetalleEntradaId/${entradaId}`;
        return this.http.get<DetalleEntrada[]>(ruta);
    }
    EntrasExcel(entradas: ExcelEntradas[]): Observable<RespuestaEntradasExcel>{
        const ruta = `${this.ruta}/ObtenerDetalleEntrada/${entradas}`; //TODO!!!
        return this.http.get<RespuestaEntradasExcel>(ruta);
    }
    CrearEntrada(almacenId: number, usuarioId: number, folio: string, nombreCompleto: string): Observable<Entrada>{
        const ruta = `${this.ruta}/CrearEntrada/${almacenId},${usuarioId},${folio},${nombreCompleto}`;
        return this.http.get<Entrada>(ruta);
    }

    cargarPedidos(almacenId: number): Observable<Pedido[]>{
        const ruta = `${this.ruta}/cargarPedidos/${almacenId}`;
        return this.http.get<Pedido[]>(ruta);
    }

    ObtenerDetallePedidos(idsPedido: number): Observable<DetallePedido> {
        const ruta = `${this.ruta}/ObtenerDetallePedidos/${idsPedido}`;
        return this.http.get<DetallePedido>(ruta);
    }

    ObtenerListaProductosPorAlmacen(almacenId: number): Observable<Productos[]>{
        const ruta = `${this.ruta}/ObtenerListaProductosPorAlmacen/${almacenId}`;
        return this.http.get<Productos[]>(ruta);
    }
    ObtenerFechaUltimaEntrada(almacenId: number): Observable<Date>{
        const ruta = `${this.ruta}/ObtenerFechaUltimaEntrada/${almacenId}`;
        return this.http.get<Date>(ruta);
    }
    ObtenerInventario(almacenId: number, productoId: number): Observable<Inventario[]>{
        const ruta = `${this.ruta}/ObtenerInventario/${almacenId},${productoId}`;
        return this.http.get<Inventario[]>(ruta);
    }

    PedidoFolio(pedidoId: number): Observable<Pedido>{
        const ruta = `${this.ruta}/PedidoFolio/${pedidoId}`;
        return this.http.get<Pedido>(ruta);
    }
    ObtenerTipoMotivos(): Observable<any>{
        const ruta = `${this.ruta}/ObtenerTipoMotivos/`;
        return this.http.get<any>(ruta);
    }

    //#region Peticiones POST ***

    AgregarAlInventario(entrada: Entrada): Observable<Respuesta<Inventario>>{
        const ruta = `${this.ruta}/AgregarAlInventario`;
        return this.http.post<Respuesta<Inventario>>(ruta, entrada);
    }

    AgregarAlPedido(entrada: Entrada, estado: number): Observable<Respuesta<Pedido>>{
        const ruta = `${this.ruta}/AgregarAlPedido`;
        entrada.estado = estado;
        return this.http.post<Respuesta<Pedido>>(ruta, entrada);
    }

    EnviarEntradaBD(entrada: Entrada): Observable<Respuesta<Entrada>>{
        const ruta = `${this.ruta}/EnviarEntradaBD`;
        return this.http.post<Respuesta<Entrada>>(ruta, entrada);
    }

    ModificarEntradaBD(entrada: Entrada): Observable<Respuesta<Entrada>>{
        const ruta = `${this.ruta}/ModificarEntradaBD`;
        return this.http.post<Respuesta<Entrada>>(ruta, entrada);
    }

}