import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
//import { Entrada } from '../../models/entrada';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { Salida } from 'src/app/shared/models/Salida';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-entrada',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteSalidaCatalogoComponent implements OnInit {
  columnas = ['Cantidad', 'CantidadRequisicion', 'Producto', 'Unidad'];

  /*get requisiciones() {
    return this.args.asignacionPedidos.map((a) => a.pedidoFolio).join(', ');
  }*/

  constructor(
    public dialogRef: MatDialogRef<ComprobanteSalidaCatalogoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public args: Salida,
    private exportAsService: ExportAsService
  ) { }

  ngOnInit() { }

  regresar() {
    this.dialogRef.close();
  }
}
