import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Entrada } from '../../../models/entrada';
import { ExportAsService } from 'ngx-export-as';
import { Subscription } from 'rxjs';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { DetalleEntrada } from 'src/app/shared/models/detalle-entrada';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-entrada',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteEntradaComponent implements OnInit {
  columnas = ['Cantidad', 'CantidadRequisicion', 'Producto', 'Unidad'];
  fuenteDatos: MatTableDataSource<DetalleEntrada> = new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  sub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ComprobanteEntradaComponent>,
    @Inject(MAT_DIALOG_DATA) public args: Entrada,
    private exportAsService: ExportAsService,
    public ventana: MatDialog,
    private db: Contexto,
    private dialog: MatDialog,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private alerta: ServicioAlerta,

  ) { }

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );
  }

  cargarTabla(): void {
    this.db.controlEntradas.ObtenerDetalleEntradaId(this.args[0].id).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        this.alerta.mostrarError('Error al cargar los detalles de la entrada');
      })
  }

  regresar() {
    this.dialogRef.close();
  }
}
