import { Detalle } from './detalle';

export class DetallePedidoSalida extends Detalle {
  pedidoId: number;
  cantidadFaltante: number;
  cantidadEntregada: number;
  bloqueado: boolean = false;
  remarcar: boolean = false;
  maquinariaId: number;
  maquinariaDescripcion: string;
}
